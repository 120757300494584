import React, {useEffect, useState} from "react";
import {useStudentApiClient} from "../../../apiClients/StudentApiClient";
import "./AllStudentCourses.scss";
import LoadMorePagination from "../../../../../components/pagination/LoadMorePagination/LoadMorePagination";
import {useNavigate} from "react-router-dom";
import {usePaymentApiClient} from "../../../apiClients/PaymentApiClient";
import Button from "../../../../../components/button/Button";
import Pagination from "../../../../../components/pagination/Pagination";
import {AllToasts} from "../../../../../components/toast/AllToasts";
import moment from "moment/moment";

export const AllStudentCourses = () => {
    const paymentApiClient = usePaymentApiClient();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCourseChange, setSelectedCourseChange] = useState<boolean>(true);
    const [enrolledCourses, setEnrolledCourses] = useState<Record<number, boolean>>({});
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(12);
    const [totalElements, setTotalElements] = useState<number>(0)
    const [studentCourses, setStudentCourses] = useState<any[]>([]);
    const [loadingCourses, setLoadingCourses] = useState<Record<number, boolean>>({});
    const [query , setQuery] = useState<string | undefined>(undefined);
    const studentApiClient = useStudentApiClient();

    useEffect(() => {
        getAllCourses(currentPage, pageSize , query)
    }, []);

    useEffect(() => {
        setStudentCourses(studentCourses);
    }, [studentCourses]);

    const navigate = useNavigate();

    const getAllCourses = (page?: number, size?: number, query?:string) => {
        setLoading(true);
        studentApiClient.getAllCoursesForStudent(currentPage, pageSize,query)
            .then((res: any) => {
                let old = page || 0;
                old++;
                setCurrentPage(old);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setPageSize(res.data.pageable?.pageSize);
                let newData = page !== 0 ? [...studentCourses] : [];
                res.data.content.map((currentData: any) => {
                    newData.push(currentData);
                });
                setLoading(false);
                setStudentCourses(newData);
            })
            .catch((err: any) => {
                console.error(err);
                setLoading(false);
            });
    };
    //
    // const getAllCourses = (page?: number, size?: number , query?: string) => {
    //     setLoading(true);
    //     studentApiClient.getAllCoursesForStudent(page, size , query)
    //         .then((res: any) => {
    //             setCurrentPage(res.data.pageable?.pageNumber);
    //             setTotalPages(res.data.totalPages);
    //             setTotalElements(res.data.totalElements);
    //             setPageSize(res.data.pageable?.pageSize);
    //             setStudentCourses(res.data.content);
    //             setLoading(false);
    //         })
    //         .catch((err: any) => {
    //             console.error(err);
    //             setLoading(false);
    //         });
    // };


    // const assignCourseToStudent = (courseId: number) => {
    //     studentApiClient
    //         .assignCourseToStudentByStudent(courseId)
    //         .then((res) => {
    //             setEnrolledCourses((prevEnrollments) => ({
    //                 ...prevEnrollments,
    //                 [courseId]: true,
    //             }));
    //         })
    //         .catch((err) => {
    //             console.error("Enrollment error", err);
    //         });
    // };

    const toggleCourseChange = () => {
        setSelectedCourseChange(!selectedCourseChange);
    };

    const handleEnroll = (courseId: number) => {
        if (!enrolledCourses[courseId]) {
            setLoadingCourses((prevLoadingCourses) => ({
                ...prevLoadingCourses,
                [courseId]: true,
            }));

            studentApiClient
                .assignCourseToStudentByStudent(courseId)
                .then((res) => {
                    setEnrolledCourses((prevEnrollments) => ({
                        ...prevEnrollments,
                        [courseId]: true,
                    }));
                    AllToasts.success('Free Trail Started')
                })
                .catch((err) => {
                    console.error("Enrollment error", err);
                    AllToasts.failure(`Failed ${err.message}`)
                })
                .finally(() => {
                    setLoadingCourses((prevLoadingCourses) => ({
                        ...prevLoadingCourses,
                        [courseId]: false,
                    }));
                });
        }
    };

    const isEnrolled = (courseId: number) => {
        return enrolledCourses[courseId] || false;
    };

    return (
        <div className={`container`}>
            <div className={"col-12 mt-3 col-sm-6"}>
                <div>
                    <h5><b>Explore Courses</b></h5>
                </div>
                {/*<div className="form-group searchBar position-relative w-auto">*/}
                {/*    <input*/}
                {/*        type={"search"}*/}
                {/*        className="form-control w-100 formControl"*/}
                {/*        placeholder={"Search here ..."}*/}
                {/*        onChange={(event) => setQuery(event.target.value)}*/}
                {/*    />*/}
                {/*    <button className={'bg-transparent transition p-0 border-0 '}><i*/}
                {/*        className="bi bi-search"></i>*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
            {/*{selectedCourseChange ? (*/}
            {/*    <div className='d-flex align-items-center justify-content-center'>*/}
            {/*        <button className="btn btn-explore mb-5 mt-5" onClick={toggleCourseChange}>*/}
            {/*            Explore More Courses <i className="bi bi-chevron-double-down"></i>*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/**/}
            {/*) : (*/}
            {/*    <div>*/}
            {/*<hr/>*/}
            {/*<div className='d-flex align-items-center justify-content-between'>*/}
            {/*    <h4>Explore More Courses</h4>*/}
            {/*    <button className="" onClick={toggleCourseChange}>*/}
            {/*        <i className="bi bi-x-lg"></i> Close*/}
            {/*    </button>*/}

            {/*</div>*/}
            <div className={'row'}>
                <div className="d-grid mx-auto thr_grid ">
                    {studentCourses && studentCourses?.map((res: any) => (
                        <div key={res.id} className={`student-course-cards`}>
                            <div className="card shadow-sm rounded-4 border-0 explore-course-card">
                                <div className="card-body p-0">
                                    <div className='d-flex justify-content-center align-items-center' style={{position:'relative'}}>
                                        <div style={{width:'90%',position:'relative',marginTop:'10px'}}>
                                            <img
                                                src={res.imageUrl
                                                    ? res.imageUrl
                                                    : 'https://static.vecteezy.com/system/resources/previews/013/115/384/non_2x/cartoon-maths-elements-background-education-logo-vector.jpg'}
                                                alt={''}
                                                style={{
                                                    height: 'auto',
                                                    width: '100%',
                                                    maxHeight: '160px',
                                                    objectFit: 'cover',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={'px-3'}>
                                        <div className="card-title pt-2 mb-1">
                                            <h5>{res.name ? res.name : 'name'}</h5></div>
                                        <div
                                            className="card-description">{res.description ? res.description : 'description'}</div>
                                        {/*<div className={'crd_date mt-2'}>Created At {moment(res.createdDate).format("DD MMM")}</div>*/}
                                    </div>
                                    {/*<hr/>*/}
                                    <div
                                        className={'d-flex justify-content-between align-items-center px-3 mb-1'}>
                                        <div className={'course_programs'}>
                                            <i className="bi bi-file-earmark-text fs-5"
                                               style={{color: '#9E9E9E'}}></i>
                                            <span className='fs-7'
                                                  style={{color: '#9E9E9E'}}>
                                                {res.programs.length} Programs
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className={'d-flex justify-content-between align-items-center px-3 pb-1'}>
                                        <div className={'course_programs'}>
                                            <span className='fs-7'
                                                  style={{color: 'rgba(33, 33, 33, 0.90)' , fontSize:'18px'}}>
                                                ₹ {res.amount}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={'d-flex justify-content-between align-items-center px-3 pb-3'}>
                                        {/*<div className='flex-fill'></div>*/}
                                        <div>
                                            {isEnrolled(res.id) ? (
                                                <button className="freeTrailButton me-2"
                                                        onClick={() => {
                                                            navigate(`course-batch/${res.id}`);
                                                        }}>
                                                    Start Now
                                                </button>
                                            ) : (
                                                <>
                                                    {loadingCourses[res.id] ? (
                                                        <button className="freeTrailButton ms-2"
                                                                type={'button'}
                                                                onClick={() => {
                                                                    handleEnroll(res.id);
                                                                }}>
                                                           Try for Free
                                                            <span className="spinner-border spinner-border-sm" role="status"
                                                                  aria-hidden="true"></span>
                                                        </button>
                                                    ) : (
                                                        <button className="freeTrailButton ms-2"
                                                                type={'button'}
                                                                onClick={() => {
                                                                    handleEnroll(res.id);
                                                                }}>
                                                            Try for Free
                                                        </button>
                                                    )}
                                                </>
                                            )}

                                        </div>
                                        <div className={'res_btn'}>
                                            {/*{isEnrolled(res.id) ? (*/}
                                            {/*    <>*/}
                                            {/*        /!*<button className='btn btn-res'>Enrolled</button>*!/*/}
                                            {/*        <p className="btn btn-strt enroll "*/}
                                            {/*           onClick={() => {*/}
                                            {/*               navigate(`course-batch/${res.id}`);*/}
                                            {/*           }}>*/}
                                            {/*            Start Now*/}
                                            {/*        </p>*/}
                                            {/*    </>*/}
                                            {/*) : (*/}
                                            <button className=" buyNowButton ms-2 ps-4 pe-4"
                                                    type={'button'}
                                                    onClick={() => {
                                                        navigate(`course-details/${res.id}`)
                                                    }}>
                                                Buy Now
                                            </button>
                                            {/*)}*/}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div>
                <div className={'mt-4'}>
                    {(totalPages > 1) && (currentPage !== totalPages) ?
                        <LoadMorePagination
                            currentPage={currentPage}
                            totalPage={totalPages}
                            change={() => getAllCourses(currentPage, pageSize)}
                            loading={loading}
                        /> : null}
                    {/*<Pagination currentPage={currentPage} totalPages={totalPages}*/}
                    {/*            onPageChange={(page) => getAllCourses(page, pageSize)} totalElements={totalElements}/>*/}
                </div>
            </div>
            {/*</div>*/}
            {/*)}*/}
            {/**/}
        </div>
    );
}
