import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Header} from "../../../../../components/common/header/Header";
import RazorpayComponent from "../../../components/razorpay/Razorpay";
import {CourseRepo} from "../../../../../repos/CourseRepo";
import {RazorPayPaymentValidationDTO} from "../../../../../modals/RazorPayModals";
import {AxiosError} from "axios";
import {usePaymentApiClient} from "../../../apiClients/PaymentApiClient";
import './CourseDetails.scss'
import {Rating} from "react-simple-star-rating";
import {AllToasts} from "../../../../../components/toast/AllToasts";
import {useStudentApiClient} from "../../../apiClients/StudentApiClient";
import moment from "moment";
import {CoursePurchaseModal} from "../../../../../components/modal/coursePurchase/CoursePurchaseModal";

enum Tab {
    Description = "Description",
    Details = "Details"
}

export enum SubscriptionForCourse {
    Monthly = 'Monthly',
    Annually = 'Annually'
}

export interface CouponRequest {
    code: string,
    newCoursePrice: number
}

const CourseDetails = () => {
    const params = useParams();
    const [courseData, setCourseData] = useState<any>();
    const [paymentStatus, setPaymentStatus] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Description);
    const paymentApiClient = usePaymentApiClient();
    const [enrolledCourses, setEnrolledCourses] = useState<Record<number, boolean>>({});
    const [studentCourses, setStudentCourses] = useState<any[]>([]);
    const [loadingCourses, setLoadingCourses] = useState<Record<number, boolean>>({});
    const [recentCourses, setRecentCourses] = useState<any>();
    const [course, setCourse] = useState<any[]>([]);
    const [subscriptionType, setSubscriptionType] = useState<boolean>(true);
    const [selectedPrice, setSelectedPrice] = useState<number | undefined>(undefined)
    const [rating, setRating] = useState(0);
    const [subscription, setSubscription] = useState<SubscriptionForCourse>(SubscriptionForCourse.Monthly)
    const studentApiClient = useStudentApiClient();
    const [couponsData, setCouponsData] = useState<any[]>([]);
    const [codeForValidation, setCodeForValidation] = useState<string | undefined>(undefined);
    const [newCoursePrice, setNewCoursePrice] = useState<number | undefined>(undefined);
    const [code, setCode] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [showPurchaseSuccessModal, setShowPurchaseSuccessModal] = useState<boolean>(false)
    const {createOrderForCourser} = usePaymentApiClient();
    // console.log("#51",newCoursePrice)
    useEffect(() => {
        getCourseById();
        getCouponsByCourse();
        getStudentCourses();
    }, []);
    const createOrder = () => {
        createOrderForCourser(Number(params.courseId), subscription, newCoursePrice).then(res => {
            handleApplyCoupon(Number(params.courseId) ?? 0 , {code: code ?? '' , newCoursePrice: newCoursePrice ?? 0});
        });
    }

    useEffect(() => {
        getRecentlyViewedCourses(Number(params.courseId))
    }, [params]);

    useEffect(() => {
        setStudentCourses(studentCourses);
    }, [studentCourses]);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(undefined);
                setCodeForValidation('');
            }, 3000);
        }
    }, [error]);

    useEffect(() => {
        const selectedCourse = course?.find((c: any) => c.id.toString() === params.courseId);
        if (selectedCourse) {
            const isExpired = moment().isAfter(selectedCourse.endDate);
            setSubscriptionType(isExpired);
        }
    }, [course, params.courseId]);

    useEffect(() => {
        if (newCoursePrice) {
            handleValidateCoupon(+(params.courseId || 0), code ?? '', selectedPrice ?? 0);
        }
    }, [selectedPrice]);


    const getCourseById = () => {
        CourseRepo.getSingleCourse(params.courseId ?? "")
            .then((res) => {
                setCourseData(res.data);
            })
            .catch((err) => console.log(err));
    };

    const handleSelectedMethod = (method: Tab) => {
        setSelectedTab(method);
    };

    const handleSuccess = (response: RazorPayPaymentValidationDTO) => {
        setPaymentStatus(false)
        if (response.courseId) {
            // ToDO: Add required checks
            let UpdatedPayload = {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature
            }
            paymentApiClient.verifyPayment(UpdatedPayload).then((res) => {
                // ToDO: Next flow implement
                console.log("#108", res);
                getStudentCourses();
                if (code && newCoursePrice) {
                    handleApplyCoupon(response.courseId ?? 0, {code: code, newCoursePrice: newCoursePrice})
                }
                setPaymentStatus(true);
            }).catch((err: AxiosError) => {
                // Todo: Show error in Toast and set loading false
                console.error("Enrollment error", err);
                setPaymentStatus(false)
            });
        }
    }

    const handleFailure = (response: any) => {
        console.log('Payment Failed', response);
    }

    const handleEnroll = (courseId: number) => {
        if (!enrolledCourses[courseId]) {
            setLoadingCourses((prevLoadingCourses) => ({
                ...prevLoadingCourses,
                [courseId]: true,
            }));

            studentApiClient
                .assignCourseToStudentByStudent(courseId)
                .then((res) => {
                    setEnrolledCourses((prevEnrollments) => ({
                        ...prevEnrollments,
                        [courseId]: true,
                    }));
                    AllToasts.success('Free Trail Started')
                })
                .catch((err) => {
                    console.error("Enrollment error", err);
                    AllToasts.failure(`Failed ${err.message}`)
                })
                .finally(() => {
                    setLoadingCourses((prevLoadingCourses) => ({
                        ...prevLoadingCourses,
                        [courseId]: false,
                    }));
                });
        }
    };

    const getStudentCourses = () => {
        studentApiClient.getAllStudentCourseBatches().then((res) => {
            setCourse(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const getRecentlyViewedCourses = (courseId: number) => {
        CourseRepo.getRecentlyViewedCoursesByStudent(courseId).then((res) => {
            setRecentCourses(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const isEnrolled = (courseId: number) => {
        return enrolledCourses[courseId] || false;
    };

    // Catch Rating value
    const handleRating = (rate: number) => {
        setRating(rate)
    }
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value: number, index: number) => console.log(value, index)
    const navigate = useNavigate();

    const handleMonthlyAmount = () => {
        if (courseData && courseData.amount) {
            setSelectedPrice(courseData.amount);
            setSubscription(SubscriptionForCourse.Monthly)
        }
    }

    const handleAnnualyAmount = () => {
        setSelectedPrice(courseData?.annualAmount);
        setSubscription(SubscriptionForCourse.Annually)
    }

    const getCouponsByCourse = () => {
        studentApiClient.getCouponsByCourse(+(params?.courseId || 0)).then(res => {
            setCouponsData(res.data);
        })
    }

    const handleApplyCoupon = (courseId: number, payload: CouponRequest) => {
        studentApiClient.applyCoupon(courseId, payload).then(res => {
        })
    }

    const handleValidateCoupon = (courseId: number, code: string, amount: number) => {
        studentApiClient.validateCoupon(courseId, code, amount).then(res => {
            setCode(code);
            setNewCoursePrice(parseFloat(res.data.toFixed(2)));
        }).catch((error: any) => {
            setError(error.response.data.reason);
        })
    }

    const handleRemoveCoupon = () => {
        setCode(undefined);
        setNewCoursePrice(undefined);
        setCodeForValidation('');
    }

    useEffect(() => {
        handleMonthlyAmount();
    }, [courseData]);


    return (
        <div className={'course_detail'}>
            <Header/>
            <div className="page-title-area item-bg1 jarallax">
                <div className="container">
                    <div className="page-title-content">
                        <ul>
                            <li><a className="text-decoration-underline cursorPointer" onClick={() => {
                                navigate(-1);
                            }}>Home</a></li>
                            <li><a className="text-decoration-underline cursorPointer" onClick={() => {
                                navigate(-1);
                            }}>Courses</a></li>
                            <li>{courseData?.name}</li>
                        </ul>
                        <h3>Course Details</h3>
                    </div>
                </div>
            </div>
            <div className={'course_detail_area mt-5'}>
                <div className={'container'}>
                    <div className={'course_detail_header mb-5'}>
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <button className={'btn btn-outline-blue mb-4 '}
                                        onClick={() => {
                                            navigate(-1);
                                        }}>
                                    <i className={'bi bi-arrow-left'}>Back</i>
                                </button>
                                <div className="courses-title">
                                    <h2>{courseData?.name}</h2>
                                    {/*<p>{courseData.description && courseData.description.substring(0 , 50)+`${courseData.description && courseData.description.length > 50 ? ' ....' : ''}`}</p>*/}
                                </div>

                                <div className="courses-meta mt-4">
                                    <ul>
                                        <li>
                                            <i className="bi bi-people"></i>
                                            <span>Students Enrolled</span>
                                            <a href="#">{courseData?.studentsEnrolled}</a>
                                        </li>
                                        <li>
                                            <i className="bi bi-question-circle"></i>
                                            <span>Total Questions</span>
                                            <a href="#">{courseData?.totalQuestions}</a>
                                        </li>
                                        <li>
                                            <i className="bi bi-card-checklist"></i>
                                            <span>Total Programs</span>
                                            <a href="#">{courseData?.totalPrograms}</a>
                                        </li>
                                        <li>
                                            <i className="bi bi-calendar2-week"></i>
                                            <span>Last Updated</span>
                                            <a href="#">{moment(courseData?.updatedDate).format('DD/MM/YYYY')}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="courses-price">
                                    <div>
                                        <div className="courses-review">
                                            <Rating
                                                onClick={handleRating}
                                                onPointerEnter={onPointerEnter}
                                                onPointerLeave={onPointerLeave}
                                                onPointerMove={onPointerMove}
                                            />(12)
                                        </div>

                                        <div className="price">Rs {selectedPrice}</div>
                                        <div className='price_wrap mt-1'>
                                            <div className='me-4'>
                                                <label>
                                                    <input onClick={handleMonthlyAmount} name={'amount'} type={'radio'}
                                                           defaultChecked={true}/> Monthly
                                                </label>
                                            </div>
                                            {courseData?.annualAmount &&
                                                <div>
                                                    <label>
                                                        <input onClick={handleAnnualyAmount} name={'amount'}
                                                               type={'radio'}/> Annually
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={'mob_btn'}>
                                        <a href="#mobbtn" className="default-btn rounded-2 text-decoration-none"><i
                                            className="bx bx-paper-plane icon-arrow before"></i><span className="label">Buy Course</span><i
                                            className="bx bx-paper-plane icon-arrow after"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-md-8'}>
                            <div className="courses-details-image text-center">
                                <img
                                    src={`${courseData?.imageUrl ?? 'https://www.mnmolympiad.com/wp-content/uploads/2020/07/MATHS-OLYMPIAD-POSTER.png'}`}
                                    alt={'course image'}
                                />
                            </div>
                            <div className={'courses-details-desc mt-3'}>
                                <div>
                                    <div className='nav-tabs ps-3 d-flex justify-content-between align-items-center'>
                                        <div className='d-flex'>
                                            <div className={`studentTab ${
                                                selectedTab === Tab.Description ? 'tabButtons' : 'tabTextColors '}`}
                                                 onClick={() => handleSelectedMethod(Tab.Description)}>
                                                <div>Description</div>
                                            </div>
                                            <div className={`studentTab ${
                                                selectedTab === Tab.Details ? 'tabButtons' : 'tabTextColors'}`}
                                                 onClick={() => handleSelectedMethod(Tab.Details)}>
                                                <span className="button-label">Program Details</span>
                                            </div>
                                        </div>
                                    </div>
                                    {selectedTab === Tab.Description &&
                                        <div className='mt-2'>
                                            {courseData?.description ?? "Description"}
                                        </div>
                                    }

                                    {selectedTab === Tab.Details &&
                                        <div
                                            className='d-flex align-items-center justify-content-between bg-light p-3 mt-4'>
                                            <div className='text-secondary d-flex flex-column'>
                                                <div>
                                                    Course
                                                    Programs: {courseData?.programs?.map((res: any) => res.name).join(', ')}
                                                </div>
                                                <div className={'mt-4'}>
                                                    {courseData?.programDetails ?? ''}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-4'}>
                            <div className={'applyCoupon border rounded-2 py-4'} id="mobbtn">
                                <div className={'code'}>
                                    <a><small>Have a coupon code ? redeem here</small></a>
                                </div>
                                <div className={'mt-3 d-flex justify-content-around'}>
                                    <div>
                                        <form>
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="exampleInputName"
                                                       aria-describedby="nameHelp" placeholder="Enter coupon code"
                                                       value={codeForValidation}
                                                       onChange={(event) => setCodeForValidation(event.target.value)}/>
                                            </div>
                                        </form>
                                    </div>
                                    <div className={'ms-2'}>
                                        <button type="submit" className="btn btn-outline-blue"
                                                onClick={() => handleValidateCoupon(+(params?.courseId || 0), codeForValidation ?? '', selectedPrice ?? 0)}
                                                disabled={!codeForValidation}>Apply
                                        </button>
                                    </div>
                                </div>
                                {error &&
                                    <div className={'text-center'}>
                                        <small className={'text-danger'}>{error} !</small>
                                    </div>
                                }
                                {
                                    couponsData.length > 0 ?
                                        <div>
                                            <div className={'text-center mt-3'}>
                                                <h5>OR</h5>
                                            </div>
                                            <div className={'text-center mt-3 pb-3'}>
                                                <h6>Available coupons</h6>
                                            </div>
                                            <div className={'couponList mx-3 border rounded-2'}>
                                                {
                                                    couponsData.map(coupon => (
                                                        <div className={`singleCoupon shadow-sm row align-items-center`}
                                                             style={{backgroundColor: `${(code === coupon.code && code !== undefined) ? "rgba(255,255,255,0.1)" : "white"}`}}>
                                                            <div className={'col'}
                                                                 style={{fontSize: '13px'}}>{coupon?.code}</div>
                                                            <div className={'col d-flex justify-content-end'}><small
                                                                className={'code'}>{coupon?.discount}%
                                                                OFF</small></div>
                                                            <div className={'col d-flex justify-content-end'}>
                                                                <button
                                                                    className={'btn bg-body-secondary code fw-bold'}
                                                                    style={{fontSize: "11px"}}
                                                                    onClick={() => {
                                                                        handleValidateCoupon(+(params?.courseId || 0), coupon.code, selectedPrice ?? 0)
                                                                    }}
                                                                    disabled={code === coupon.code && code !== undefined}
                                                                >
                                                                    Apply
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div> : ''
                                }
                                {
                                    newCoursePrice !== undefined &&
                                    <div
                                        className={'d-flex justify-content-center align-items-center text-primary mt-3'}>
                                        <h5>{code} applied !</h5><span
                                        className={'ms-3 pb-1 text-danger cursorPointer'}><a
                                        onClick={handleRemoveCoupon}>Remove</a></span>
                                    </div>
                                }
                                <div className={'mx-3 mt-4'}>
                                    <h6>Final Breakdown</h6>
                                </div>
                                <div className={'mx-3'}>
                                    <div className={'d-flex justify-content-between align-items-center mt-2 mb-2'}>
                                        <div><span><small>Course Price</small></span></div>
                                        <div><span>Rs. {selectedPrice}</span></div>
                                    </div>
                                </div>
                                {
                                    newCoursePrice !== undefined &&
                                    <div className={'mx-3'}>
                                        <div className={'d-flex justify-content-between align-items-center'}>
                                            <div><span><small>Discount</small></span></div>
                                            <div>
                                                <span>-{parseFloat(((selectedPrice ?? 0) - newCoursePrice).toFixed(2))}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={'mx-3'}>
                                    <div className={'d-flex justify-content-between align-items-center'}>
                                        <div><span className={'fw-bold'}>Total</span></div>
                                        <div
                                            className={'fw-bold'}>{newCoursePrice !== undefined ? `Rs. ${parseFloat(String(newCoursePrice)).toFixed(2)}` : `Rs. ${selectedPrice}`}</div>
                                    </div>
                                </div>
                                <div className={'mt-4 d-flex justify-content-center'}>
                                    {
                                        newCoursePrice === 0 ? (
                                            <button className={'btn default-btn w-75 butn butn__new '} onClick={() => {
                                                createOrder()
                                                setShowPurchaseSuccessModal(true)
                                            }}>
                                                {/*{buttonState.isLoading ? (*/}
                                                {/*    <div className={'d-flex align-items-center justify-content-center'}>*/}
                                                {/*        <div className={'spinner-border me-2'}></div>*/}
                                                {/*        <div>Buy Now</div>*/}
                                                {/*    </div>*/}
                                                {/*) : (*/}
                                                {/*    'Buy Now'*/}
                                                {/*)}*/}
                                                Buy Now
                                            </button>
                                        ) : (
                                            <RazorpayComponent
                                                courseId={Number(params.courseId)}
                                                custom_className="btn default-btn"
                                                onSuccess={handleSuccess}
                                                onFailure={handleFailure}
                                                paymentStatus={paymentStatus}
                                                subscriptionType={subscriptionType}
                                                subscription={subscription}
                                                newCoursePrice={newCoursePrice}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CoursePurchaseModal show={showPurchaseSuccessModal} onHide={() => setShowPurchaseSuccessModal(false)}
                                 courseId={Number(params.courseId)}/>
        </div>
    );
};
export default CourseDetails;
