import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useStudentApi} from "../../../../api/StudentApiClent";
import {SessionManager} from "../../../../configs/SessionManager";
import {InputUtils} from "../../../../utils/InputUtils";
import {LoginScope} from "../../../../dto/Payload";
import {GOOGLE_CLIENT_ID} from "../../../../common/AppConstants";

export interface StudentLoginUsingId {
    username: string,
    password: string,
    scope: LoginScope
}

const LoginStudentUserId = () => {
    const navigate = useNavigate();
    const [payload, setPayload] = useState<StudentLoginUsingId>({
        password: '',
        username: '',
        scope: LoginScope.Student
    });
    const studentApi = useStudentApi();
    const [error, setError] = useState<string>();
    const [loginButtonDisabled , setLoginButtonDisabled] = useState<boolean>(false) ;

    useEffect(() => {
        google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: handleCallBackResponse
        })
        google.accounts.id.renderButton(
            document.getElementById("signInDiv") as HTMLElement,
            {theme: "filled_blue", size: "large", type: "standard",text:"continue_with" ,logo_alignment:"left",shape:"circle"}
        );

        google.accounts.id.prompt();
    }, []);

    const handleCallBackResponse = (res: any) => {
        console.log("#41", res.credential);
        passwordLogin({username: "$GOOGLE$", password: res.credential, scope: LoginScope.Student});
    }

    const passwordLogin = (payload:StudentLoginUsingId) => {
        setError(undefined);
        setLoginButtonDisabled(true) ;
        studentApi.userIdPasswordLogin(payload).then(res => {
            SessionManager.createSession(res.data.access_token, '', res.data);
            navigate("/student")
        }).catch(err => {
            setError(`Login Error: Invalid username / password`)
        }).finally(() => {
            setLoginButtonDisabled(false) ;
        })
    }

    const handleChange = (event: ChangeEvent<HTMLDivElement>) => {
        const inp = InputUtils.parseInput(event);
        setPayload((prevState) => ({...prevState, [inp.name]: inp.value}));
    }
    const submitForm = (evt: FormEvent) => {
        evt.preventDefault();
        passwordLogin(payload) ;
    }
    return <div>
        <form className="mt-3 w-100" onSubmit={submitForm}>
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}
            <div className="mb-3">
                <label className="form-label d-flex" htmlFor="studentId">
                    Student Id
                </label>
                <input id="studentId"
                       placeholder="Student Id" className="form-control m-auto " type="text" name="username"
                       onChange={handleChange}
                />
            </div>
            <div className="mb-2">
                <label className="form-label" htmlFor="password">
                    Password
                </label>
                <input id="password" placeholder="Enter Password" className="form-control m-auto"
                       type="password" name="password" onChange={handleChange}
                />
            </div>
            <div className={'loginBtn mt-5'}>
                <button
                    className='btn btn-otp w-100 text-white' disabled={loginButtonDisabled}>Login
                </button>
            </div>
            <div id="signInDiv" className="mb-3 d-flex justify-content-center"
                 style={{marginTop: "50px"}}>
            </div>
        </form>
    </div>
}
export default LoginStudentUserId