import React, {useEffect, useState} from "react";
import Pagination from "../../../../components/pagination/Pagination";
import {ConfirmationDialog} from "../../../../components/confirmationDialog/ConfirmationDialog";
import {AllToasts} from "../../../../components/toast/AllToasts";
import {useAdminApiClient} from "../../apiClients/AdminApiClient";
import moment from "moment";
import AddEditNotice from "../../../../components/modal/notice/AddEditNotice";

interface TableData {
    id: number | undefined,
    content: string,
    createdDate: string,
    publishedDate: string,
}

const NoticeBoard = () => {
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [noticePayload, setNoticePayload] = useState<TableData[]>([]);
    const [noticeId, setNoticeId] = useState<number | undefined>(0)
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('')
    const adminApiClient = useAdminApiClient();

    useEffect(() => {
        getAllNoticeForStudent()
    }, [])

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            getAllNoticeForStudent(pageSize, 0)
        }, 500)
        return () => {
            clearTimeout(delayTimer)
        }
    }, [query]);


    const getAllNoticeForStudent = (size?: number, page?: number) => {
        setLoading(true)
        adminApiClient.getAllNoticeForAdmin(size, page, query).then((res) => {
            setLoading(false)
            setNoticePayload(res.data.content)
            setCurrentPage(res.data.number);
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements);
        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }

    const deleteNotice = (id: any) => {
        adminApiClient.deleteNotice(id ?? 0)
            .then(() => {
                AllToasts.success('Notice Deleted successfully');
                getAllNoticeForStudent();
            })
            .catch((error) => {
                console.error('Error deleting notice :', error);
            })
    };

    const onAction = (isConfirm: boolean) => {
        if (!isConfirm) {
            setShowDelete(false)
            setNoticeId(undefined)
        } else {
            deleteNotice(noticeId ?? 0);
            setShowDelete(false)
            setNoticeId(undefined)
        }
    }


    return <div className='container-fluid page-content'>
        <div className="row page-titles">
            <div className="col-sm-6 p-md-0">
                <div className="welcome-text">
                    <h4>All Notices</h4>
                </div>
            </div>
            <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item d-flex" aria-current="page">Home</li>
                    <li className="breadcrumb-item text-primary">All Notices</li>
                </ol>
            </div>
        </div>
        <div className={'card border-0 shadow-sm'}>
            <div className={'card-body'}>
                <div className={'d-md-flex justify-content-between align-items-center'}>
                    <div className="col-md-6 px-0">
                        <div className="form-group searchBar position-relative">
                            <input
                                type={"search"}
                                className="form-control w-100 formControl"
                                placeholder={"Search here ..."}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <button className={'bg-transparent transition p-0 border-0'}>  <i className="bi bi-search"></i></button>
                        </div>
                    </div>

                    <div>
                        <button className={"btn btn-primary text-white rounded-1 btn-add"}
                                onClick={() => setShow(true)}><i className="bi bi-plus-lg"></i> Add new
                        </button>
                    </div>
                </div>

                {loading ? (
                    ""
                ) : noticePayload?.length > 0 ? (
                    <div>
                        <div className={'table-responsive'}>
                            <table className="table studentTable mt-4">
                                <thead>
                                <tr>
                                    <th scope="col-1">Sr.No</th>
                                    <th scope="col">Content</th>
                                    <th scope="col">Created On</th>
                                    <th scope="col">Published On</th>
                                    <th scope="col">Actions</th>
                                </tr>
                                </thead>

                                <tbody>
                                {noticePayload?.map((res, index) => (
                                    <tr key={res.id}>
                                        <td>{index + 1}</td>
                                        <td className={'text-capitalize'}>{res.content}</td>

                                        <td>{moment(res.createdDate).format('d MMMM, YYYY')}</td>
                                        <td>{res.publishedDate ? moment(res.publishedDate).format('D MMMM, YYYY') : '--'}</td>
                                        <td>
                                            <div className={'actions d-flex'}>
                                                <a className={'btn btn-sm bg-success-light me-2 rounded-circle'}
                                                   onClick={() => {
                                                       setNoticeId(res.id);
                                                       setShow(true)
                                                   }}
                                                >
                                                    <i className="bi bi-pen"></i>
                                                </a>
                                                <button
                                                    onClick={() => {
                                                        setNoticeId(res.id);
                                                        setShowDelete(true);
                                                    }}
                                                >
                                                    <i className="bi bi-trash text-danger ms-2"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination currentPage={currentPage} totalPages={totalPages} totalElements={totalElements}
                                    onPageChange={(page) => getAllNoticeForStudent(pageSize, page)}/>
                    </div>

                ) : (
                    <div className={'d-flex justify-content-center mt-5 h-100'}>
                        <h6>No results found !</h6>
                    </div>
                )}
            </div>
        </div>


        <AddEditNotice
            noticeId={noticeId ?? 0}
            show={show}
            onHide={() => {
                setShow(false);
                setNoticeId(undefined);
            }}
            onCancel={() => {
                setShow(false)
                setNoticeId(undefined);
            }}
            onSuccess={() => {
                setNoticeId(undefined);
                getAllNoticeForStudent();
                setShow(false);
            }}
        />

        <ConfirmationDialog title={'Delete Notice'} description={"Are you sure you want to delete this notice?"}
                            show={showDelete} onAction={onAction}
                            onClose={() => setShowDelete(false)}/>
    </div>
}
export default NoticeBoard;