import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import { PieChart } from "./PieChart";
import "./ResultScreeen.scss";
import { useStudentApiClient } from "../../../apiClients/StudentApiClient";
import { useNavigate, useParams } from "react-router-dom";
import { QuizMode } from "../../../../../modals/quizquestionData/Modal";
import { StudentTestResponse } from "../../../../../modals/quizquestionData/Modal";
import successGif from '../../../../../assets/images/success.gif';
import lowScore from '../../../../../assets/images/lowScore.gif';

Chart.register(...registerables);

const ResultScreen = () => {
    const [resultData, setResultData] = useState<StudentTestResponse>();
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const studentApiClient = useStudentApiClient()

    useEffect(() => {
        if (params.testType === "ASSIGNMENT") {
            getAssignmentResult();
        } else if (params.testType === "TEST" || params.testType === "PRACTISE" || params.resultId) {
            getTestResult();
        }
    }, [params]);

    const getTestResult = () => {
        setLoading(true);
        studentApiClient.getStudentTestResult(parseInt(params?.resultId ?? '')).then((res: any) => {
            setResultData(res.data);
            setLoading(false);
        }).catch(() => {
            console.log("#24");
            setLoading(false);
        });
    };

    const getAssignmentResult = () => {
        studentApiClient.getAssignmentResult(parseInt(params?.resultId ?? '')).then((res: any) => {
            setResultData(res.data);
        }).catch(() => {
            console.log("#24");
        });
    };

    const correctPercentage = resultData
        ? ((resultData.correct ?? 0) / (resultData.totalMarks ?? 1)) * 100
        : null;

    return (
        <div>
            <div className={'container d-flex flex-column bg-white'}>
                <div className={'row my-4 chapterHeader align-items-center justify-content-between'}>
                    <div className={'col-6'}>
                        <h6 className={'fw-bold'}>Test Results</h6>
                    </div>
                    <div className={'col-6 resultIcons d-flex justify-content-end'}>
                        {params.testType === QuizMode.TEST &&
                            <button className={'btn btn-review'} onClick={() => navigate(`../Test/review/${params.resultId}`)}>
                                Test Review
                            </button>}
                        {params.testType === QuizMode.ASSIGNMENT &&
                            <button className={'btn btn-review'} onClick={() => navigate(`../Assignment/review/${params.resultId}`)}>
                                Test Review
                            </button>}
                        {params.testType === QuizMode.PRACTISE &&
                            <button className={'btn btn-review'} onClick={() => navigate(`../Practise/review/${params.resultId}`)}>
                                Test Review
                            </button>}
                    </div>
                </div>
                {correctPercentage !== null ? (
                    <div>
                        {!loading && correctPercentage >= 60 &&
                            <div className={'scoreContainer text-center'}>
                                <h6 className={'text-center fw-bold pt-3 mb-5'}> Well done! Your Knowledge shines brightly</h6>
                                <div>
                                    <img src={successGif} alt="Low Percentage" />
                                </div>
                                <div className={'scoreBox'}>
                                    <h6 className={'fw-bold'}>Score</h6>
                                    <h5 className={'mt-1 mb-3'}>
                                        <b>{resultData?.obtainedMarks ?? 0}/</b>{resultData?.totalMarks ?? 0}
                                    </h5>
                                </div>
                            </div>}
                        {!loading && correctPercentage < 60 &&
                            <div className={'scoreContainer text-center'}>
                                <h6 className={'text-center fw-bold pt-3 mb-5'}> Keep pushing, success is just around the corner!</h6>
                                <div>
                                    <img style={{ width: '100px' }} src={lowScore} alt="High Percentage" />
                                </div>
                                <div className={'scoreBox'}>
                                    <h6 className={'fw-bold'}>Score</h6>
                                    <h5 className={'mt-1 mb-3'}>
                                        <b>{resultData?.obtainedMarks ?? 0}/</b>{resultData?.totalMarks ?? 0}
                                    </h5>
                                </div>
                            </div>}
                    </div>
                ) : (
                    <div className="text-center mt-4">

                        {/*{loading ? "Calculating resluts..." : "No result data available."}*/}
                        {/*<i className='spinner-border'></i>*/}
                    </div>
                )}
            </div>
            <div className={'container pieChartContainer shadow-sm bg-white pb-3'} >
                <div className={'flex-fill pieChart'}>
                    <PieChart correctCount={resultData?.correct ?? 0} wrongCount={resultData?.wrong ?? 0}
                              skippedCount={resultData?.skipped ?? 0} />
                </div>
                <div className={'col resultIcons'}>
                    <div>
                        <div className="p-1 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <span className="status-icon correct pt-1 me-2" style={{ width: '20px', height: '20px' }} />
                                <h6>Correct({resultData?.correct ?? 0})</h6>
                            </div>
                        </div>

                        <div className="p-1 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <span className="status-icon wrong pt-1 me-2" style={{ width: '20px', height: '20px' }} />
                                <h6>Wrong ({resultData?.wrong ?? 0})</h6>
                            </div>
                        </div>

                        <div className="p-1 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <span className="status-icon skipped pt-1 me-2" style={{ width: '20px', height: '20px' }} />
                                <h6>Skipped ({resultData?.skipped ?? 0})</h6>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ResultScreen;
