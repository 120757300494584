import './App.scss';
import "./assets/css/style.scss";
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Route, Routes} from "react-router-dom";
import {TeacherIndex} from "./modules/teacher/TeacherIndex";
import {AdminIndex} from "./modules/admin/AdminIndex";
import {StudentIndex} from "./modules/student/StudentIndex";
import StudentLogin from "./modules/student/screen/login/StudentLogin";
import AdminLogin from "./modules/admin/screen/login/AdminLogin";
import TeacherLogin from "./modules/teacher/screen/TeacherLogin";
import {AuthGuard} from "./components/auth/AuthGuard";
import {Home} from "./modules/common/Home";
import SelfLogin from "./modules/student/screen/login/selfRegistration/SelfLogin";
import LoginSuccess from "./modules/student/screen/login/selfRegistration/LoginSuccess";
import {UserProvider} from "./context/UserContext";
import DataEntryExecutiveLogin from "./modules/dataEntryExecutive/screen/login/DataEntryExecutiveLogin";
import {DataEntryExecutiveIndex} from "./modules/dataEntryExecutive/screen/DataEntryExecutiveIndex";

function App() {

    return (
        <div className="App">
            <UserProvider>
                <Routes>
                    <Route path={"/"} element={<Home/>}/>
                    <Route path={"/student/login"} element={<StudentLogin/>}/>
                    <Route path={"/student/login/register"} element={<SelfLogin/>}/>
                    <Route path={"/student/login/success"} element={<LoginSuccess/>}/>
                    <Route path={"/dataEntry/login"} element={<DataEntryExecutiveLogin/>}/>
                    <Route path={"/admin/login"} element={<AdminLogin/>}/>
                    <Route path={"/teacher/login"} element={<TeacherLogin/>}/>
                    <Route path={"/teacher/*"}
                           element={<AuthGuard component={TeacherIndex} redirectTo={"/teacher/login"}/>}/>
                    <Route path={"/admin/*"} element={<AuthGuard component={AdminIndex} redirectTo={"/admin/login"}/>}/>
                    <Route path={"/student/*"}
                           element={<AuthGuard component={StudentIndex} redirectTo={"/student/login"}/>}/>
                    <Route path={"/dataEntry/*"}
                           element={<AuthGuard component={DataEntryExecutiveIndex} redirectTo={"/dataEntry/login"}/>}/>
                </Routes>
            </UserProvider>
        </div>
    );
}

export default App;
