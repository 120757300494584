import StudentChapterShimmer from "./StudentChapterShimmer";
import TabSelector from "../tabSelector/TabSelector";
import {EmptyState} from "../../../../components/emptyState/EmptyState";
import {StudentNoOfQuestionsModal} from "../topic/StudentNoOfQuestionsModal";
import React, {useEffect, useState} from "react";
import {QuizMode} from "../../../../modals/quizquestionData/Modal";
import {useNavigate, useParams} from "react-router-dom";
import {useStudentApiClient} from "../../apiClients/StudentApiClient";
import {ProgramRepo} from "../../../../repos/ProgramRepo";
import './StudentChapter.scss'

interface Topic {
    id: number,
    name: string,
    chapterId: number,
    topic_chapterId: string,
    hasComp?:boolean,
}

interface Chapter {
    id: number,
    name: string,
    topics: Topic[],
    active: boolean,
    hasComprehension?:boolean
}

export const StudentChapterPaid = () => {
    const [selectAllStates, setSelectAllStates] = useState<{ [key: number]: boolean }>({});
    const [chapters, setChapters] = useState<Chapter[]>([])
    const [selectedChapters, setSelectedChapters] = useState<Chapter[]>([]);
    const [selectedTopics, setSelectedTopics] = useState<Topic[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedTestType, setselectedTestType] = useState<QuizMode>(QuizMode.TEST);
    const [numberOfQuestions, setNumberOfQuestions] = useState('');
    const [show, setShow] = useState<boolean>(false);
    const [studentRank, setStudentRank] = useState<any>();
    const [showChapterTopics, setShowChapterTopics] = useState<{ [key: number]: boolean }>({});
    const navigate = useNavigate()
    const params = useParams();
    const useStudentApi = useStudentApiClient()
    const studentApiClient = useStudentApiClient();
    const [canHaveComprehensions, setCanHaveComprehensions] = useState<boolean>(false);
    const [ofComp, setOfComp] = useState<boolean>(false);
    const [numberOfComp, setNumberOfComp] = useState<number | undefined>(undefined);


    useEffect(() => {
        getAllChapters();
    }, [params.programId])

    useEffect(() => {
        getStudentRankForAllCourses()
    }, []);

    useEffect(() => {
        if (params.programId)
            getProgramId(params.programId);
    }, [params.programId])

    // useEffect(() => {
    //     console.log("#57" , selectedChapters)
    //     console.log("#58" , selectedTopics)
    // }, [selectedChapters , selectedTopics]);

    const getAllChapters = () => {
        studentApiClient.getAllProgramChapters(parseInt(params?.programId ?? '')).then((res: any) => {
            setChapters(res.data);
            setLoading(false);
        }).catch(() => {
            console.log("#30")
        })
    }

    const getSelectedTopicCount = (chapter: Chapter) => {
        return chapter.topics.filter((topic) =>
            selectedTopics.some((selectedTopic) => selectedTopic.name === topic.name && selectedTopic.topic_chapterId === `${selectedTopic.id}_${chapter.id}`)).length;
    };

    const handleChapterChange = (chapter: Chapter) => {
        // Reset all chapters to closed and only open the selected chapter
        setShowChapterTopics((prevState) => ({
            ...Object.keys(prevState).reduce((acc, key) => {
                acc[parseInt(key)] = false; // Close all chapters
                return acc;
            }, {} as { [key: number]: boolean }),
            [chapter.id]: !prevState[chapter.id], // Toggle the selected chapter
        }));
    };


    const handleTopicChange = (chapterId: number, topic: Topic) => {
        const topicId = `${topic.id}_${chapterId}`;
        const topicIndex = selectedTopics.findIndex((t) => t.id === topic.id && t.chapterId === chapterId);
        const updatedSelectedTopics = [...selectedTopics];
        let updatedSelectedChapters = [...selectedChapters];
        if (topicIndex !== -1) {
            updatedSelectedTopics.splice(topicIndex, 1);
        } else {
            updatedSelectedTopics.push({id: topic.id, name: topic.name, chapterId, topic_chapterId: topicId});
        }
        const chapter = chapters.find((c) => c.id === chapterId);
        if (chapter) {
            const selectedTopicsInChapter = chapter.topics.filter((t) =>
                updatedSelectedTopics.some((selectedTopic) => selectedTopic.id === t.id && selectedTopic.chapterId === chapterId)
            );
            setSelectAllStates((prevState) => ({
                ...prevState,
                [chapterId]: selectedTopicsInChapter.length === chapter.topics.length,
            }));
            if (selectedTopicsInChapter.length === chapter.topics.length) {
                if (!updatedSelectedChapters.find((c) => c.id === chapterId)) {
                    updatedSelectedChapters = [...updatedSelectedChapters, chapter];
                }
            } else {
                updatedSelectedChapters = updatedSelectedChapters.filter((c) => c.id !== chapterId);
            }
        }
        setSelectedTopics(updatedSelectedTopics);
        setSelectedChapters(updatedSelectedChapters);
    };

    const handleSelectAllChange = (chapterId: number) => {
        const updatedSelectAllStates = {...selectAllStates};
        updatedSelectAllStates[chapterId] = !updatedSelectAllStates[chapterId];
        const updatedSelectedTopics = [...selectedTopics];
        const chapter = chapters.find((c) => c.id === chapterId);
        if (chapter) {
            if (updatedSelectAllStates[chapterId]) {
                updatedSelectedTopics.push(...chapter.topics.map((topic) => ({
                    id: topic.id,
                    name: topic.name,
                    chapterId,
                    topic_chapterId: `${topic.id}_${chapterId}`,
                })));
            } else {
                updatedSelectedTopics.splice(
                    updatedSelectedTopics.findIndex((t) =>
                        chapter?.topics.some((selectedTopic) => selectedTopic.name === t.name && t.chapterId === chapterId)
                    ),
                    chapter.topics.length
                );
            }
            setSelectedTopics(updatedSelectedTopics);
            if (updatedSelectAllStates[chapterId]) {
                setSelectedChapters([...selectedChapters, chapter]);
            } else {
                setSelectedChapters(selectedChapters.filter((c) => c.id !== chapterId));
            }
        }
        setSelectAllStates(updatedSelectAllStates);
    };

    const handleSelectAllChaptersChange = () => {
        const newSelectAllState = !(selectedChapters.length === chapters.length);
        if (newSelectAllState) {
            const allChapters = chapters.map((chapter) => {
                const selectedChapter = {
                    id: chapter.id,
                    name: chapter.name,
                    topics: chapter.topics,
                    active: chapter.active
                };
                return selectedChapter;
            });
            setSelectedChapters([...allChapters]);
            const allTopics = chapters.flatMap((chapter) =>
                chapter.topics.map((topic) => ({
                    id: topic.id,
                    name: topic.name,
                    chapterId: chapter.id,
                    topic_chapterId: `${topic.id}_${chapter.id}`,
                })));
            setSelectedTopics([...allTopics]);
        } else {
            setSelectedChapters([]);
            setSelectedTopics([]);
        }
        const updatedShowChapterTopics: { [key: number]: boolean } = {};
        chapters.forEach((chapter) => {
            updatedShowChapterTopics[chapter.id] = false
        });
        setShowChapterTopics(updatedShowChapterTopics);
        const updatedSelectAllStates: { [key: number]: boolean } = {};
        chapters.forEach((chapter) => {
            updatedSelectAllStates[chapter.id] = newSelectAllState;
        });
        setSelectAllStates(updatedSelectAllStates);
    };

    const handleNumberOfQuestions = (number: string) => {
        setNumberOfQuestions(number);
    };

    const createTest = () => {
        if (ofComp) {
            useStudentApi.createCompTest(Number(params.courseBatchId), selectedTopics.map((topic) => topic.id),
                Number(params.programId), selectedTestType, numberOfComp).then((res) => {
                navigate(`../${selectedTestType}/${res.data.id}/comp-instructions`)
            }).catch(() => {
            })
        } else {
            useStudentApi.createTest(Number(params.courseBatchId), selectedTopics?.map((topic) => topic.id),
                Number(params.programId), selectedTestType, studentRank?.percentage, parseInt(numberOfQuestions, 10)).then((res) => {
                navigate(`../${selectedTestType}/${res.data.id}/instructions`)
            }).catch(() => {
            })
        }
    }

    const getStudentRankForAllCourses = () => {
        studentApiClient.getStudentRank(params.courseBatchId ?? 0 as any).then((res) => {
            setStudentRank(res.data)
        }).then((error) => {
            console.log(error)
        })
    }

    const handleOptionClick = (options: string) => {
        if (options === QuizMode.TEST) {
            setselectedTestType(QuizMode.TEST);
        } else if (options === QuizMode.PRACTISE) {
            setselectedTestType(QuizMode.PRACTISE)
        }
    };

    const getProgramId = (programId: string) => {
        ProgramRepo.getSingleProgram(programId).then((res) => {
            setCanHaveComprehensions(res.data.canHaveComprehensions);
        });
    };

    const handleNumberOfComp = (number: number) => {
        setNumberOfComp(number);
    }

    const handleComprehensionCheckboxChange = (e:any) => {
        setOfComp(e.target.checked);
        setSelectedChapters([]);
        setSelectedTopics([]);

        const resetSelectAllStates: { [key: number]: boolean } = {};
        const resetShowChapterTopics: { [key: number]: boolean } = {};

        chapters.forEach((chapter) => {
            resetSelectAllStates[chapter.id] = false;
            resetShowChapterTopics[chapter.id] = false;
        });

        setSelectAllStates(resetSelectAllStates);
        setShowChapterTopics(resetShowChapterTopics);
    };

    return <div>
        <div>
            {loading ? <StudentChapterShimmer count={7}/> : <div>
                {
                    chapters.length > 0 ? <div className={'container'}>
                        <div className={'row mt-4 mb-4 align-items-end'}>
                            <div className={'col-md-12 wrapper'}>
                                <div>
                                    <div>
                                        <h6 className={'fw-bold'}>Choose Chapters</h6>
                                    </div>
                                    <div className={'d-flex'}>
                                        <TabSelector option={selectedTestType} handleOptionClick={handleOptionClick}/>
                                    </div>
                                </div>
                                <div className="d-sm-flex align-items-center wrap_c">
                                    <div
                                        className={`bg-white py-1 px-3 rounded-4 border d-flex align-items-center py-1`}>
                                        <input
                                            type="checkbox"
                                            className="inputCheckbox me-3"
                                            checked={selectedChapters.length === chapters.length}
                                            onChange={() => handleSelectAllChaptersChange()}
                                        />
                                        <p className={'mb-0'}>Select All Chapters</p>
                                    </div>
                                    <div>
                                        {
                                            canHaveComprehensions ?
                                                <div
                                                    className={`bg-white py-1 px-3 rounded-4 border ms-sm-4 h-50 mt-sm-0 mt-3 d-flex align-items-center py-1`}>
                                                    <input
                                                        type="checkbox"
                                                        className="inputCheckbox me-3"
                                                        checked={ofComp}
                                                        onChange={handleComprehensionCheckboxChange}
                                                    />
                                                    <p className={'mb-0'}>Only comprehensions</p>
                                                </div> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row multi-selector mt-2 h-100 mb-6 mb-sm-0">
                            {chapters?.map((chapter) => (
                                <div className={`col-md-4 `} key={chapter.id}>
                                    <div
                                        className={`position-relative chapter-wrapper mb-2 ${showChapterTopics[chapter.id] ? 'expanded' : ''}`}
                                        key={chapter.id}
                                    >
                                        <div
                                            className={`d-flex justify-content-between chapter-item shadow-sm ${
                                                (chapter.topics.length === 0 || (ofComp ===true && chapter.hasComprehension === false) ) ? 'disabled-chapter' : ''
                                            } ${showChapterTopics[chapter.id] ? 'active-chapter' : ''}`}
                                        >
                                            <div className="d-flex align-items-center">
                                                <input
                                                    type="checkbox"
                                                    className="inputCheckbox mx-2"
                                                    checked={selectAllStates[chapter.id] || false}
                                                    onChange={() => handleSelectAllChange(chapter.id)}
                                                    disabled={chapter.topics.length === 0 || (ofComp ===true && chapter.hasComprehension === false)} // Disable checkbox if no topics
                                                />
                                                <span
                                                    className={`chapter-label py-2 text-capitalize ${
                                                        chapter.topics.length === 0 ? 'disabled-label' : ''
                                                    }`}
                                                    onClick={() => chapter.topics.length > 0 && handleChapterChange(chapter)} // Prevent click if no topics
                                                >
              {chapter.name}
            </span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                {getSelectedTopicCount(chapter) > 0 && (
                                                    <div
                                                        className="topicCount mx-2">{getSelectedTopicCount(chapter)}</div>
                                                )}
                                                <div className="mx-2 chevron-container">
                                                    <i
                                                        className={`bi bi-chevron-${showChapterTopics[chapter.id] ? 'up' : 'down'} fs-6 chevron-icon`}
                                                        onClick={() => chapter.topics.length > 0 && handleChapterChange(chapter)} // Prevent chevron action if no topics
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Show topic list only if chapter has topics and is expanded */}
                                        {showChapterTopics[chapter.id] && chapter.topics.length > 0 && (
                                            <ul className="topic-list shadow-sm">
                                                {chapter.topics.map((topic) => (
                                                    <li className="topic-item" key={topic.id}>
                                                        <label className={`d-flex align-items-center ps-2 ${ofComp === true && topic.hasComp === false ? 'disabled-chapter' : ''}`}
                                                               htmlFor={topic.id.toString()}>
                                                            <input
                                                                className={`inputCheckbox me-2`}
                                                                id={topic.id.toString()}
                                                                type="checkbox"
                                                                checked={selectedTopics.some(
                                                                    (t) =>
                                                                        t.name === topic.name && t.topic_chapterId === `${t.id}_${chapter.id}`
                                                                )}
                                                                onChange={() => handleTopicChange(chapter.id, topic)}
                                                                disabled={(ofComp === true && topic.hasComp === false)}
                                                            />
                                                            {topic.name}
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}

                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className='sticky-bottom-btns text-end'>
                            <button className={"btn btn-success text-white me-3"} onClick={() => setShow(true)}
                                    disabled={!(selectedChapters.length > 0 || selectedTopics.length > 0)}>
                                {selectedTestType === QuizMode.PRACTISE ? "Start Practice Test" : "Start Test"}
                            </button>
                        </div>
                    </div> : <EmptyState/>
                }
            </div>}
            <StudentNoOfQuestionsModal show={show} onHide={() => setShow(false)} createTest={createTest}
                                       numberOfQuestions={handleNumberOfQuestions} ofComp={ofComp}
                                       numberOfComp={handleNumberOfComp}/>

        </div>
    </div>
}