import {QuestionData} from "../quizStepper/QuizStepper";
import "./submitButtonEarly.css"

export const SubmitButtonEarly=(props:{onClick:()=>void,currentQuestionIndex:number,questionData:QuestionData[]})=>{
    return <div className={''}>
        <button className={`submitEarlyBtn btn  ${(props.currentQuestionIndex+1 !== props.questionData.length ) ? '' : 'active'}`}
                onClick={props.onClick}>
            Submit Test
        </button>
    </div>
}
export default SubmitButtonEarly;