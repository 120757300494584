import {QuestionData, StepperProps} from "./QuizStepper";
import React, {useEffect, useState} from "react";
import "./QuizStepper.scss";

const ReviewStepper = (props: StepperProps) => {
    const [questions, setQuestions] = useState<QuestionData[]>([]);

    useEffect(() => {
        setQuestions(props.questionData);
    }, [props.questionData]);

    const numRows = Math.ceil(questions.length / 5);
    const numCols = 5; // Number of columns

    return (
        <div className="stepper row">
            <div className="Question-header">
                <h5 className="mt-2" style={{fontWeight: '600'}}>Questions</h5>
            </div>
            <div className={'stepper-container py-3'}>
                {Array.from({length: numRows}, (_, rowIndex) => (
                    <div className="stepper-row" key={rowIndex}>
                        {Array.from({length: numCols}, (_, colIndex) => {
                            const index = rowIndex * numCols + colIndex;
                            const question = questions[index];
                            const status = question?.status ?? null;

                            // Apply break if no question exists at this index
                            if (!question) {
                                return null;
                            }

                            // Assign class based on question status
                            let statusClass = "";
                            if (status === "Skipped") {
                                statusClass = "skipped";
                            } else if (status === "Correct") {
                                statusClass = "correct";
                            } else if (status === "Wrong") {
                                statusClass = "wrong";
                            }

                            return (
                                <div
                                    onClick={() => props.onClick(index)}
                                    key={index}
                                    className={`step ${statusClass} ${props.currentStep === index ? "current" : ""}`}
                                >
                                    <label className="radio-label">
                                        <span className="radio-number">{index + 1}</span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
            <div className={'indicatorContainer d-flex  align-items-center'}>
                <div className={'d-flex align-items-center me-5 ms-4'}>
                    <div className={'indicator-step Correct'}>
                    </div>
                    <div className={'py-2 indicator_label'}>Correct</div>
                </div>
                <div className={'d-flex align-items-center me-5'}>
                    <div className={'indicator-step Wrong'}>
                    </div>
                    <div className={'py-2 indicator_label'}>Wrong</div>
                </div>
                <div className={'d-flex align-items-center'}>
                    <div className={'indicator-step Skipped'}>
                    </div>
                    <div className={'py-2 indicator_label'}>Skipped</div>
                </div>
            </div>
        </div>
    );
};

export default ReviewStepper;
