import React, {useEffect, useState} from "react";
import './studentAchievement.css';
import Reward1 from "../../../../assets/images/Reward1.svg";
import Reward2 from "../../../../assets/images/Reward2.svg";
import Reward3 from "../../../../assets/images/Reward3.svg";
import Reward4 from "../../../../assets/images/Reward4.svg";
import Reward5 from "../../../../assets/images/Reward5.svg";
import RewardGREY5 from "../../../../assets/images/RewardGREY5.svg";
import RewardGREY4 from "../../../../assets/images/RewardGREY4.svg";
import RewardGREY3 from "../../../../assets/images/RewardGREY3.svg";
import RewardGREY2 from "../../../../assets/images/RewardGREY2.svg";
import ProgressBar from "../../../../components/common/progressBar/ProgressBar";
import {useStudentApiClient} from "../../apiClients/StudentApiClient";
import {useParams} from "react-router-dom";
import {ProgramDTO} from "../../../../dto/StudentRewards";



export const StudentAchievement = () => {
    const studentApiClient = useStudentApiClient();
    const params = useParams();
    const [rewards, setRewards] = useState<ProgramDTO>();

    useEffect(() => {
        getStudentRewards(Number(params.courseBatchId))
    }, []);
    const getStudentRewards = (courseId:number) => {
        studentApiClient.getStudentRewards(courseId).then((res) => {
            setRewards(res.data);
            console.log('setRewards',res.data)
        }).catch((err) => {
            console.log(err);
        });
    }

    return <div className={'container-fluid mb-xxl-5'}>
        <div className={'bg-white rounded-2 p-3  mt-4'}>
            <div className='mb-4'>
                <div className={''}>
                    <h6 style={{color: '#3498DB'}}>Badges</h6>
                </div>
                <div className={' rounded-2 p-3  mt-3 d-flex align-items-center justify-content-between '}
                     style={{backgroundColor: 'rgba(195, 195, 195, 0.20)'}}>
                    <div className='mt-3 mb-3'>
                        <img className=' p-1 m-1 ' src={Reward1} alt="Selected Image" style={{width: '90%'}}/>
                    </div>
                    <div className='mt-3 mb-3'>
                        {/*<img  className={` p-1 m-1` }  src={Reward2} alt="Selected Image" style={{width:'90%'}}/>*/}
                        <img className={` p-1 m-1`} src={RewardGREY2} alt="Selected Image" style={{width: '90%'}}/>
                    </div>
                    <div className='mt-3 mb-3'>
                        {/*<img className='p-1 m-1 ' src={Reward3} alt="Selected Image" style={{width:'90%'}}/>*/}
                        <img className=' p-1 m-1 ' src={RewardGREY3} alt="Selected Image" style={{width: '90%'}}/>
                    </div>
                    <div className='mt-3 mb-3'>
                        {/*<img className=' p-1 m-1 ' src={Reward4} alt="Selected Image" style={{width:'90%'}}/>*/}
                        <img className=' p-1 m-1 ' src={RewardGREY4} alt="Selected Image" style={{width: '90%'}}/>
                    </div>
                    <div className='mt-3 mb-3'>
                        {/*<img className=' p-1 m-1 ' src={Reward5} alt="Selected Image" style={{width:'90%'}}/>*/}
                        <img className='p-1 m-1 ' src={RewardGREY5} alt="Selected Image" style={{width: '90%'}}/>
                    </div>
                </div>
            </div>
            <div className=''>
                {!rewards ? (
                    <div className="d-flex justify-content-center align-items-center" style={{height: '100px'}}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div>
                        {rewards?.name && (
                            <div className={''}>
                                <h6 style={{color: '#3498DB'}}>Certificates</h6>
                            </div>
                        )}
                        {rewards?.name && (
                            <div className={' rounded-2 p-3 d-flex justify-content-between align-items-center mt-3 '}
                                 style={{backgroundColor: 'rgba(195, 195, 195, 0.20)'}}>
                                <div className='col-10'>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33"
                                             viewBox="0 0 33 33" fill="none">
                                            <g clip-path="url(#clip0_528_2298)">
                                                <path
                                                    d="M20.9 31.9H19.8C19.8 32.3167 20.0354 32.6975 20.4081 32.8838C20.7807 33.0702 21.2267 33.0299 21.56 32.78L20.9 31.9ZM25.3 28.6L25.96 27.72C25.5688 27.4267 25.0312 27.4267 24.64 27.72L25.3 28.6ZM29.7 31.9L29.04 32.78C29.3733 33.0299 29.8192 33.0702 30.1919 32.8838C30.5646 32.6975 30.8 32.3167 30.8 31.9H29.7ZM25.3 24.2C22.2625 24.2 19.8 21.7376 19.8 18.7H17.6C17.6 22.9526 21.0474 26.4 25.3 26.4V24.2ZM30.8 18.7C30.8 21.7376 28.3375 24.2 25.3 24.2V26.4C29.5526 26.4 33 22.9526 33 18.7H30.8ZM25.3 13.2C28.3375 13.2 30.8 15.6624 30.8 18.7H33C33 14.4474 29.5526 11 25.3 11V13.2ZM25.3 11C21.0474 11 17.6 14.4474 17.6 18.7H19.8C19.8 15.6624 22.2625 13.2 25.3 13.2V11ZM19.8 23.1V31.9H22V23.1H19.8ZM21.56 32.78L25.96 29.48L24.64 27.72L20.24 31.02L21.56 32.78ZM24.64 29.48L29.04 32.78L30.36 31.02L25.96 27.72L24.64 29.48ZM30.8 31.9V23.1H28.6V31.9H30.8ZM33 11V3.3H30.8V11H33ZM29.7 0H3.3V2.2H29.7V0ZM0 3.3V29.7H2.2V3.3H0ZM3.3 33H17.6V30.8H3.3V33ZM0 29.7C0 31.5225 1.47746 33 3.3 33V30.8C2.69249 30.8 2.2 30.3074 2.2 29.7H0ZM3.3 0C1.47746 0 0 1.47746 0 3.3H2.2C2.2 2.69249 2.69249 2.2 3.3 2.2V0ZM33 3.3C33 1.47746 31.5225 0 29.7 0V2.2C30.3074 2.2 30.8 2.69249 30.8 3.3H33ZM6.6 11H17.6V8.8H6.6V11ZM6.6 17.6H13.2V15.4H6.6V17.6Z"
                                                    fill="black" fill-opacity="0.9"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_528_2298">
                                                    <rect width="33" height="33" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span className='ms-3' style={{
                                            fontSize: '20px',
                                            fontWeight: '500'
                                        }}> {rewards ? rewards.name : '--'} </span>
                                    </div>
                                    <div className='mt-3'>
                            <span style={{fontSize: '15px', color: 'rgba(0, 0, 0, 0.80)'}}>
                                {rewards ? `${rewards.attemptedTopics || 0}/${rewards.totalTopics || 0} Topics Completed` : 'Loading...'}
                            </span>
                                        <div className='mt-2'>
                                            <ProgressBar
                                                totalPoints={rewards?.totalTopics || 0}
                                                completedPoints={rewards?.attemptedTopics || 0}
                                                height={10}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-1'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="81" height="81" viewBox="0 0 81 81"
                                         fill="none">
                                        <circle cx="40.5" cy="40.5" r="40.5" fill="#7A7A7A" fill-opacity="0.3"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M52.4117 30.9707V33.7597C55.1876 34.7409 57.1764 37.3883 57.1764 40.5001V54.7943C57.1764 58.7413 53.9764 61.9413 50.0294 61.9413H30.9705C27.0233 61.9413 23.8235 58.7413 23.8235 54.7943V40.5001C23.8235 37.3883 25.8123 34.7409 28.5882 33.7597V30.9707C28.5882 24.392 33.9213 19.059 40.5 19.059C47.0786 19.059 52.4117 24.392 52.4117 30.9707ZM33.3529 30.9707C33.3529 27.0235 36.5527 23.8237 40.5 23.8237C44.4472 23.8237 47.647 27.0235 47.647 30.9707V33.3531H33.3529V30.9707Z"
                                              fill="#212121" fill-opacity="0.8"/>
                                    </svg>
                                </div>
                            </div>
                        )}
                        {(rewards?.programs?.length ?? 0) > 0 && (
                            <div className={'rounded-2 p-3 d-flex flex-column mt-3'}>
                                <h6 className='mb-4'>Subject</h6>
                                {rewards?.programs?.map((program, index) => (
                                    <div key={index} className='d-flex justify-content-between align-items-center mb-3'>
                                        <div className='col-10'>
                                            <div className='d-flex justify-content-start align-items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     viewBox="0 0 33 33" fill="none">
                                                    <g clip-path="url(#clip0_528_2298)">
                                                        <path
                                                            d="M20.9 31.9H19.8C19.8 32.3167 20.0354 32.6975 20.4081 32.8838C20.7807 33.0702 21.2267 33.0299 21.56 32.78L20.9 31.9ZM25.3 28.6L25.96 27.72C25.5688 27.4267 25.0312 27.4267 24.64 27.72L25.3 28.6ZM29.7 31.9L29.04 32.78C29.3733 33.0299 29.8192 33.0702 30.1919 32.8838C30.5646 32.6975 30.8 32.3167 30.8 31.9H29.7ZM25.3 24.2C22.2625 24.2 19.8 21.7376 19.8 18.7H17.6C17.6 22.9526 21.0474 26.4 25.3 26.4V24.2ZM30.8 18.7C30.8 21.7376 28.3375 24.2 25.3 24.2V26.4C29.5526 26.4 33 22.9526 33 18.7H30.8ZM25.3 13.2C28.3375 13.2 30.8 15.6624 30.8 18.7H33C33 14.4474 29.5526 11 25.3 11V13.2ZM25.3 11C21.0474 11 17.6 14.4474 17.6 18.7H19.8C19.8 15.6624 22.2625 13.2 25.3 13.2V11ZM19.8 23.1V31.9H22V23.1H19.8ZM21.56 32.78L25.96 29.48L24.64 27.72L20.24 31.02L21.56 32.78ZM24.64 29.48L29.04 32.78L30.36 31.02L25.96 27.72L24.64 29.48ZM30.8 31.9V23.1H28.6V31.9H30.8ZM33 11V3.3H30.8V11H33ZM29.7 0H3.3V2.2H29.7V0ZM0 3.3V29.7H2.2V3.3H0ZM3.3 33H17.6V30.8H3.3V33ZM0 29.7C0 31.5225 1.47746 33 3.3 33V30.8C2.69249 30.8 2.2 30.3074 2.2 29.7H0ZM3.3 0C1.47746 0 0 1.47746 0 3.3H2.2C2.2 2.69249 2.69249 2.2 3.3 2.2V0ZM33 3.3C33 1.47746 31.5225 0 29.7 0V2.2C30.3074 2.2 30.8 2.69249 30.8 3.3H33ZM6.6 11H17.6V8.8H6.6V11ZM6.6 17.6H13.2V15.4H6.6V17.6Z"
                                                            fill="black" fill-opacity="0.9"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_528_2298">
                                                            <rect width="33" height="33" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span className='ms-3' style={{
                                                    fontSize: '18px',
                                                    fontWeight: '500'
                                                }}>{program.name}</span>
                                            </div>
                                            <div className='mt-3'>
                                    <span style={{fontSize: '15px', color: 'rgba(0, 0, 0, 0.80)'}}>
                                        {program.attemptedTopics}/{program.totalTopics} Topics Completed
                                    </span>
                                                <div className='mt-2'>
                                                    <ProgressBar
                                                        totalPoints={program.totalTopics ?? 0}
                                                        completedPoints={program.attemptedTopics ?? 0}
                                                        height={7}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    </div>;
}