import {useStudentApiClient} from "../../apiClients/StudentApiClient";
import {CourseBatch} from "../../../../dto/CourseBatch";
import React, {useEffect, useRef, useState} from "react";
import './StudentHome.scss'
import {AllStudentCourses} from "./courses/AllStudentCourses";
import {StudentEnrolledCourses} from "../../components/courses/StudentEnrolledCourses";
import CourseDashboard from "../../../../assets/images/CourseDashboardImage.png";
import {useNavigate} from "react-router-dom";

export enum Tab {
    Enrolled = "Enrolled",
    Explore = "Explore"
}

export const StudentHome = () => {
    const [courseBatches, setCourseBatches] = useState<CourseBatch[]>([]);
    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Enrolled);
    const [selectedCourseType, setSelectedCourseType] = useState<string>('')
    const studentApiClient = useStudentApiClient();
    const [query , setQuery] = useState<string >('');
    const [topics, setTopics] = useState([]);
    const [filteredTopics, setFilteredTopics] = useState<any[]>([]);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const navigate = useNavigate();

    const allStudentCoursesRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        console.log('selectedCourseType',selectedCourseType)
        // setCourseBatches([])
        if (selectedTab === Tab.Explore) {
            setSelectedCourseType("PAID")
        } else if (selectedTab === Tab.Enrolled) {
            if (selectedCourseType === "All") {
                getStudentCourseBatches();
            } else {
                getStudentCourseBatches(selectedCourseType);
            }
        }
    }, [selectedCourseType, selectedTab]);

    useEffect(() => {
        getAllCourse()
    }, []);

    const handleInputFocus = () => {
        setFilteredTopics(topics);
        setShowDropdown(true);
    };

    useEffect(() => {
        if (query) {
            const filtered = topics.filter((topic:any) =>
                topic.name.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredTopics(filtered);
        } else {
            setFilteredTopics(topics);
        }
    }, [query, topics]);

    const getStudentCourseBatches = (type?: string) => {
        studentApiClient.getAllStudentCourseBatches(type).then(res => {
            setCourseBatches(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    const getAllCourse = () => {
        studentApiClient.getAllExploreCourses().then(res => {
            setTopics(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleSelectTopic = (topic: any) => {
        setQuery(topic.name);
        setShowDropdown(false);
        navigate(`/student/course-details/${topic.id}`);
    };

    const handleSelectedMethod = (method: Tab) => {
        setSelectedTab(method);
    };

    const handleSelectedCourse = (courseType: string) => {
        setSelectedCourseType(courseType);
    }

    const scrollToCourses = () => {
        if (allStudentCoursesRef.current) {
            allStudentCoursesRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return <>
        <div className={'page-wrapper'}>
            <div
                style={{
                    position: 'relative',
                    display: 'inline-block',
                    width: '100%',
                    backgroundImage: `url(${CourseDashboard})`,
                    backgroundSize: 'cover',
                    backgroundPosition: '30% 33%',
                    backgroundRepeat: 'no-repeat',
                    height: '45vh',
                }}
            >
                <div className='dashboard-image-overlay'/>
                <div className='dashboard-search-button'>

                    <div className="form-group searchBar position-relative w-100" style={{ marginBottom: '1rem' }}>
                        <input
                            type="text"
                            className="form-control w-100 formControl"
                            placeholder="Search Topics / Courses"
                            value={query}
                            onChange={(event) => setQuery(event.target.value)}
                            onFocus={handleInputFocus}
                            onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
                            style={{ paddingRight: '2.5rem' }}
                        />

                        {showDropdown && filteredTopics.length > 0 && (
                            <ul className="dropdown-menu show w-100" style={{ maxHeight: '250px', overflowY: 'auto' , cursor:'pointer'}}>
                                {filteredTopics.map((topic: any, index) => (
                                    <li key={index} className="dropdown-item" onClick={() => handleSelectTopic(topic)}>
                                        {topic.name}
                                    </li>
                                ))}
                            </ul>
                        )}

                        <button className="bg-transparent transition p-0 border-0 position-absolute"
                                style={{ right: '0.5rem', top: '50%', transform: 'translateY(-50%)', zIndex: 3 }}>
                            <i className="bi bi-search" style={{ fontSize: '1.2rem' }}></i>
                        </button>
                    </div>


                    <button className="btn btn-primary " style={{background:'#3498DB', borderColor:'#3498DB', cursor:'pointer'}}  onClick={scrollToCourses}>Explore Courses</button>
                </div>

            </div>
            <div className={`p-2 pt-2 container pb-5`}>
                <div>
                    <StudentEnrolledCourses onSuccess={getStudentCourseBatches} courseBatches={courseBatches}
                                            selectedTab={selectedTab} selectedCourse={(courseType) => handleSelectedCourse(courseType)}/>
                </div>

                  <div ref={allStudentCoursesRef}  className='allStudentCourse'>
                      <AllStudentCourses/>
                  </div>

            </div>
            {/*<div className={`p-2 pt-2 container pb-5`}>*/}
            {/*    <div className=' w-100 nav-tabs ps-3 pt-3 d-flex justify-content-between align-items-center'>*/}
            {/*        <div className='d-flex'>*/}
            {/*            <div className={`studentTab me-2 p-3 ${*/}
            {/*                selectedTab === Tab.Enrolled ? 'methodButtons' : 'tabTextColor '}`}*/}
            {/*                 onClick={() => handleSelectedMethod(Tab.Enrolled)}>*/}
            {/*                <div>Enrolled</div>*/}
            {/*            </div>*/}
            {/*            <div className={`studentTab p-3 ${*/}
            {/*                selectedTab === Tab.Explore ? 'methodButtons' : 'tabTextColor'}`}*/}
            {/*                 onClick={() => handleSelectedMethod(Tab.Explore)}>*/}
            {/*                <span className="button-label">Explore Courses</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    {selectedTab === Tab.Enrolled &&*/}
            {/*        <StudentEnrolledCourses onSuccess={getStudentCourseBatches} courseBatches={courseBatches}*/}
            {/*                                selectedTab={selectedTab} selectedCourse={(courseType) =>*/}
            {/*            handleSelectedCourse(courseType)}/>*/}
            {/*    }*/}
            {/*    {selectedTab === Tab.Explore &&*/}
            {/*        <AllStudentCourses/>*/}
            {/*    }*/}
            {/*</div>*/}

        </div>
    </>
}