import {Offcanvas} from "react-bootstrap";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {useTeacherApiClient} from "../../../apiClients/TeacherApiClient";
import './../../../modal/EditQuestion.scss'
import {AllToasts} from "../../../../../components/toast/AllToasts";
import Button from "../../../../../components/button/Button";
import {Option, Questions} from "../../modals/CreateiUpdateQuestion";
import {QuizQuestion} from "../../../../student/components/quiz/quizQuestions/QuizQuestion";
import {ImageUpload} from "./ImageUpload/ImageUpload";
import DropdownSelect from "./dropDownSelect/DropDownSelect";
import {useLocation, useParams} from "react-router-dom";
import {ResolveQuestion} from "./resolveQuestion/ResolveQuestion";
import {SubjectsData} from "../../../../admin/components/AdminSubjects";
import {useSubjectsApiClient} from "../../../../admin/apiClients/SubjectsApiClient";
import Select from "react-dropdown-select";
import {inputFields, QuestionListTable} from "../QuestionListTable";
import {MathJaxCheatSheet} from "./MathJaxCheatSheet";
import {MathX} from "../../../../../components/math/MathX";
import {AuditMode} from "../../audit/Audit";
import {QuestionType} from "../../../../../modals/quizquestionData/Modal";
import {UpdateComprehensionQuestion} from "./comprehesionQuestion/UpdateComprehensionQuestion";
import {CreateComprehensionQuestion} from "./comprehesionQuestion/CreateComprehensionQuestion";
import _ from 'lodash';

interface EditQuestionProps {
    show: boolean;
    passageId?: number | undefined
    passgeText?: string | undefined
    onHide: () => void;
    onSuccess: () => void;
    questionId?: number | undefined;
    currentPage?: number;
    pageSize?: number;
    selectedOption?: string
    subjectId?: number
    topicId?: number[]
    filteredSubjectIds?: number[]
    onSelectedOptionChange?: (option: any) => void
    flaggedQuestionId?: number
    hasIssue?: boolean
    setFlaggedQuestionId?: React.Dispatch<React.SetStateAction<number | undefined>>;
    flaggedIds?: number[] | []
    allFlaggedQuestionIds?: number[]
    expandedPassageId?: number | null
    handleOnSuccess?: (query?: string | undefined, student?: number[] | undefined, teacher?: number[] | undefined, subject?: number[] | undefined, markedBy?: AuditMode, status?: string | undefined, page?: number | undefined, size?: number | undefined) => void
}

const CreateUpdateQuestions = (props: EditQuestionProps) => {
    const location = useLocation();
    const teacherApiClient = useTeacherApiClient();
    const subjectsApiClient = useSubjectsApiClient();
    const params = useParams();
    const [options, setOptions] = useState<Option[]>([{id: -1, text: '', correct: false, imageCid: '', url: ''}]);
    const [idCounter, setIdCounter] = useState<number>(0);
    const [selectedQuestionType, setSelectedQuestionType] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadedFileCid, setUploadedFileCid] = useState<string>('')
    const [imageUrl, setImageUrl] = useState<string>('')
    const [imageUploadVisible, setImageUploadVisible] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [selectedMethod, setSelectedMethod] = useState<string>('question')
    const [selectedOptionTopic, setSelectedTopic] = useState<any[]>([])
    const [topics, setTopics] = useState<any[]>([]);
    const [subjects, setSubjects] = useState<SubjectsData[]>([]);
    const [selectedOption, setSelectedOption] = useState('Single');
    const [selectedSubject, setSelectedSubject] = useState<any[]>([]);
    const [comprehensionId, setComprehensionId] = useState<number | undefined>(undefined);
    // const [currentStep, setCurrentStep] = useState(1);
    const [initialPayload, setInitialPayload] = useState<Questions>()
    const [payload, setPayload] = useState<Questions>({
        id: -1,
        text: '',
        imageCid: '',
        options: [{id: -1, text: '', correct: false}],
        topicId: [0],
        explanation: '',
        difficultyLevel: '',
        oneWordAnswer: '',
        knowledge_memory: '',
        student_preparation: '',
        analytical_thinking: '',
        concept_application: '',
        concept_clarity: '',
        problem_solving_skill: '',
        numerical_accuracy: '',
        logic: '',
        time_management: '',
        totalScore: '',
        category: '',
        type: '' as QuestionType
    });
    const [questionId, setQuestionId] = useState<number | undefined>(undefined)
    const [updateQuestionId, setUpdateQuestionId] = useState<number | undefined>(undefined)
    const [passage, setPassage] = useState<string>('');

    useEffect(() => {
        setComprehensionId(props.passageId)
    }, [props.passageId]);

    const handleClearPayload = () => {
        setPayload({
            id: -1,
            text: '',
            imageCid: '',
            options: [{id: -1, text: '', correct: false}],
            topicId: [0],
            explanation: '',
            difficultyLevel: '',
            oneWordAnswer: '',
            knowledge_memory: '',
            student_preparation: '',
            analytical_thinking: '',
            concept_application: '',
            concept_clarity: '',
            problem_solving_skill: '',
            numerical_accuracy: '',
            logic: '',
            time_management: '',
            totalScore: '',
            category: '',
            type: '' as QuestionType
        });
    }

    useEffect(() => {
        if (!comprehensionId) {
            setSelectedTopic([]);
            handleClearPayload()
        } else if (comprehensionId) {
            setSelectedQuestionType('');
            handleClearPayload()
            setOptions([{id: -1, text: '', correct: false}]);
        }
        setOptions([{id: -1, text: '', correct: false}]);
        setImageUploadVisible(false);
        if (props.questionId ?? -1 > 0) {
            getQuestionById(props.questionId ?? 0);
            setSelectedOption('Passage')
        }
    }, [props, comprehensionId]);
    useEffect(() => {
        setSelectedSubject([]);
        if (props.show)
            getAllSubjects();
    }, [props.show]);
    const getAllSubjects = () => {
        subjectsApiClient.getSubjects().then((res) => {
            setSubjects(res.data);
        }).catch((err) => {
            console.log(err)
        })
    };
    const handleToggleImageUpload = () => {
        setImageUploadVisible(!imageUploadVisible);
    };

    const getQuestionById = (id: number) => {
        teacherApiClient.getQuestionById(id).then((res) => {
            if (res.data === null) {
                setSelectedSubject([]);
                setSelectedTopic([]);
                setOptions([{id: -1, text: '', correct: false}]);
                handleClearPayload();
                setSelectedQuestionType('');
            } else {
                setSelectedTopic(res.data?.topics?.map((topic: any) => ({value: topic.id, label: topic.name})));
                setOptions(res.data.options);
                setPayload(res.data);
                // Deep copy the initial payload
                setInitialPayload(_.cloneDeep(res.data));
                setUpdateQuestionId(res.data.id);
                setImageUrl(res.data.imageUrl);
                setSelectedQuestionType(res.data.type);
                if (res.data.ofComprehension) {
                    setSelectedOption('Passage');
                } else {
                    setSelectedOption('Single');
                }
                if (res.data.imageCid) {
                    setImageUploadVisible(true);
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleOptionTextChange = (index: number, text: string,) => {
        const newOptions = [...options];
        newOptions[index].text = text;
        setOptions(newOptions);
        setPayload((prevPayload) => ({
            ...prevPayload,
            options: newOptions
        }));
    };
    const handleOptionCorrectChange = (index: number, correct: boolean) => {
        setOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[index].correct = correct;
            setPayload((prevPayload) => ({
                ...prevPayload,
                options: newOptions
            }));
            return newOptions;
        });
    };
    const handleOptionCorrectChanges = (index: number, correct: boolean) => {
        const newOptions = options.map((option, i) => {
            if (i === index) {
                // Set the correctness of the clicked option
                option.correct = correct;
            } else {
                // For "OneWord" and "TrueFalse," set all other options as false
                option.correct = false;
            }
            return option;
        });
        setOptions(newOptions);
        setPayload((prevPayload) => ({
            ...prevPayload,
            options: newOptions
        }));
    };
    const handleAddOption = () => {
        setOptions((prevOptions) => [
            ...prevOptions,
            {id: idCounter, text: "", correct: false,},
        ]);
        setPayload((prevPayload) => ({
            ...prevPayload,
            options: [
                ...prevPayload.options,
                {id: idCounter, text: "", correct: false}
            ]
        }));
        setIdCounter(idCounter + 1);
    }
    const createQuestion = () => {
        const updatedPayloadWithTotal = calculateTotalScore(payload);
        const updatedPayload = {...updatedPayloadWithTotal};
        inputFields.forEach((field) => {
            if (!updatedPayload[field.valueKey]) {
                updatedPayload[field.valueKey] = 2;
            }
        });
        const finalPayload = calculateTotalScore(updatedPayload);
        finalPayload.topicId = selectedOptionTopic?.map(topicId => topicId.value);
        finalPayload.imageCid = uploadedFileCid;
        finalPayload.type = selectedQuestionType;
        teacherApiClient.createQuestions(finalPayload, comprehensionId ?? undefined)
            .then((res) => {
                setQuestionId(res.data.id)
                setPayload(finalPayload);
                setLoading(false);
                AllToasts.success('Created Successfully');
                setUploadedFileCid('');
                setUpdateQuestionId(undefined)
                if (!comprehensionId) {
                    props.onSuccess();
                    setSelectedTopic([]);
                    props.onHide();
                } else if (comprehensionId) {

                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                AllToasts.success(`Error ${err.response?.data?.detail}`);
            });
    };

    const updateQuestion = (id: number) => {
        const updatedPayloadWithTotal = calculateTotalScore(payload);
        const updatedPayload = {...updatedPayloadWithTotal};
        inputFields.forEach((field) => {
            if (!updatedPayload[field.valueKey]) {
                updatedPayload[field.valueKey] = 2;
            }
        });
        const finalPayload = calculateTotalScore(updatedPayload);
        finalPayload.topicId = selectedOptionTopic?.map(topicId => topicId.value);
        finalPayload.imageCid = uploadedFileCid;
        finalPayload.type = selectedQuestionType;
        teacherApiClient.updateQuestion(id, finalPayload)
            .then((res) => {
                setLoading(false);
                AllToasts.success('Updated Successfully');
                setUpdateQuestionId(undefined)
                if (!comprehensionId) {
                    if (params['*'] !== 'audit') {
                        props.onHide();
                        props.onSuccess();
                        setSelectedTopic([]);
                    }
                }
                // setQuestionId(undefined)
                setUploadedFileCid('');
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                AllToasts.success(`Error ${err.response?.data?.detail}`);
            });
    };
    const handleRemoveOption = (index: number) => {
        setOptions(prevOptions => prevOptions.filter((_, i) => i !== index));
    };
    const handleChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setPayload((prevState) => ({...prevState, [name]: value}));
    };
    const handleDifficultyChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;
        setPayload((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setPayload((prevState) => calculateTotalScore(prevState));
    };
    const handleDropDownSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.currentTarget;
        setSelectedQuestionType(value);
        const optionMappings: { [key: string]: Option[] } = {
            'TrueFalse': [{id: -1, text: 'True', correct: false}, {id: -1, text: 'False', correct: false}],
            'OneAnswer': [{id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}],
            'MultiSelect': [{id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}],
            'OneWord': [{id: -1, text: '', correct: false}],
            '': [{id: -1, text: '', correct: false}]
        };
        const selectedOptions = optionMappings[value] || optionMappings[''];
        setOptions(selectedOptions);
        setPayload(prevPayload => ({...prevPayload, options: selectedOptions}));
    };
    const handleFormSubmit = (event: FormEvent) => {
        event.preventDefault();
        setLoading(true);
        if (props.questionId) {
            updateQuestion(props.questionId);
        } else {
            createQuestion();
        }
    };
    const calculateTotalScore = (inputPayload: any) => {
        const {
            difficultyLevel,
            knowledge_memory,
            student_preparation,
            analytical_thinking,
            concept_application,
            problem_solving_skill,
            numerical_accuracy,
            concept_clarity,
            logic,
            time_management,
        } = inputPayload;

        const safeParseInt = (value: string) => {
            const parsedValue = parseInt(value, 10);
            return isNaN(parsedValue) ? 0 : parsedValue;
        };

        const totalScore =
            safeParseInt(difficultyLevel) +
            safeParseInt(knowledge_memory) +
            safeParseInt(student_preparation) +
            safeParseInt(analytical_thinking) +
            safeParseInt(concept_application) +
            safeParseInt(concept_clarity) +
            safeParseInt(problem_solving_skill) +
            safeParseInt(numerical_accuracy) +
            safeParseInt(logic) +
            safeParseInt(time_management);

        return {
            ...inputPayload,
            totalScore: totalScore,
        };
    };
    const handleMethodChange = (method: 'question' | 'formula') => {
        setSelectedMethod(method);
    };
    const handleOnUploadedFileCid = (cid: string) => {
        setPayload((prev) => ({...prev, imageCid: cid}));
        setUploadedFileCid(cid)
    }
    const resetImageStates = () => {
        setImageUploadVisible(false);
        setUploadedFileCid('');
        setImageUrl('');
    };
    const handleHideModal = () => {
        setSelectedTopic([]);
        setSelectedSubject([]);
        resetImageStates();
        setSelectedOption('Single')
        props.onHide();
    };

    const handleSubjectChange = (selectedSubject: any[]) => {
        if (selectedSubject && selectedSubject.length > 0) {
            const selectedSubjectId = selectedSubject[0].value;
            const subject = subjects.find((subject) => subject.id === selectedSubjectId);
            if (subject) {
                const topicsOfSelectedSubject = subject.topics || [];
                setTopics(topicsOfSelectedSubject);
                const updatedSelectedTopics = selectedOptionTopic.filter((selectedTopic) =>
                    topicsOfSelectedSubject.some((topic) => topic.id === selectedTopic.value)
                );
                setSelectedTopic(updatedSelectedTopics);
                setPayload((prevPayload) => ({
                    ...prevPayload,
                    topicId: updatedSelectedTopics.map((topic) => topic.value),
                }));
            }
        } else {
            setTopics([]);
            setSelectedTopic([]);
            setPayload((prevPayload) => ({
                ...prevPayload,
                topicId: [],
            }));
        }
    };
    const handleTopicsChange = (selectedTopics: any[]) => {
        setSelectedTopic(selectedTopics);
        const associatedSubject = subjects.find(subject =>
            selectedTopics.every(selectedTopic =>
                subject.topics.some(topic => topic.id === selectedTopic.value)
            )
        );
        if (associatedSubject) {
            const {id, name} = associatedSubject;
            const modifiedSubject = {value: id, label: name};
            setSelectedSubject([modifiedSubject]);
            setPayload(prevPayload => ({
                ...prevPayload,
                topicId: selectedTopics.map(topic => topic.value),
            }));
        } else {
            setPayload(prevPayload => ({
                ...prevPayload,
                topicId: [],
            }));
        }
    };
    const handleImage = (url: string) => {
        setImageUrl(url)
    }
    const onRadioButtonChange = (event: any) => {
        setSelectedOption(event.target.value);
    };
    const handleOnComprehensionIdChange = (id: number) => {
        setComprehensionId(id)
    }
    const handlePassageChange = (text: string) => {
        setPassage(text)
    };

    const hanleCompImage = (url: string) => {
        setImageUrl(url)
    }

    return (
        <Offcanvas show={props.show} onHide={handleHideModal} placement='end'
                   style={{width: showPreview ? '100%' : '50%'}}>
            <Offcanvas.Header className={'m-0 p-0'}>
                <div className='w-100 nav-tabs ps-3 pt-3 d-flex justify-content-between align-items-center'>
                    <div>
                        <button className={`questionButton me-2 p-3 ${
                            selectedMethod === 'question' ? 'methodButtons' : 'text-black'}`}
                                onClick={() => handleMethodChange('question')}>
                            <span className="button-label">{props.questionId ? <div>Update Question</div> :
                                <div>Insert Question</div>}</span>
                        </button>
                        <button className={`formulaButton p-3 ${
                            selectedMethod === 'formula' ? 'methodButtons' : 'text-black'}`}
                                onClick={() => handleMethodChange('formula')}>
                            <span className="button-label">Formula Reference</span>
                        </button>
                    </div>
                    <div onClick={props.onHide} className='me-3'><i className='bi bi-x-lg'></i></div>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className={'d-flex'}>
                    {showPreview &&
                        <div className={'col-6 p-3'}>
                            {passage.length > 0 &&
                                <div className='mb-3'>
                                    <h4>Passage</h4>
                                    <textarea rows={5} className={'form-control'} value={passage}></textarea>
                                </div>
                            }
                            <div className={'d-flex'}>
                                <h4>Q.</h4>
                                <h4 style={{whiteSpace: "pre-wrap"}}><MathX>{payload.text}</MathX></h4>
                            </div>
                            <div className={'m-2'}>{imageUrl ?
                                <img src={imageUrl} className={'img-fluid'}
                                     style={{maxWidth: '700px', maxHeight: '700px'}}/> : ""}
                            </div>
                            <QuizQuestion question={payload} quizView={'teacher'}/>
                            {payload.explanation && (
                            <div className='mt-3'>
                                <h5 className="form-label">Explanation :</h5>
                                <h6 style={{whiteSpace: "pre-wrap"}}><MathX>{payload.explanation}</MathX></h6>
                            </div>
                                )}
                        </div>}
                    <div className={'flex-fill'}>
                        {selectedMethod === 'question' && (
                            <form onSubmit={handleFormSubmit}>
                                <div className='d-flex align-items-center'>
                                    <div className="mb-2 w-50 me-4">
                                        <label className="form-label" htmlFor={'subjects'}>Subject<span
                                            className='ms-1 text-danger'>*</span></label>
                                        <Select
                                            options={subjects?.map((subject: any) => ({
                                                value: subject.id,
                                                label: subject.name
                                            })) || []}
                                            onChange={(selectedSubject) => handleSubjectChange(selectedSubject)}
                                            values={selectedSubject || []} placeholder="Select Subjects" required
                                        />
                                    </div>

                                    <div className="mb-2 w-50">
                                        <label className="form-label" htmlFor={'topics'}>Topics<span
                                            className='ms-1 text-danger'>*</span></label>
                                        <Select
                                            options={topics?.map((topic: any) => ({
                                                value: topic.id,
                                                label: topic.name
                                            })) || []}
                                            onChange={(selectedTopics) => handleTopicsChange(selectedTopics)}
                                            values={selectedOptionTopic || []} placeholder="Select Topics" multi
                                            required
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className={'form-label me-3'}>
                                        <input
                                            className={'radio-item'} type={'radio'} name={'QuestionType'}
                                            value={'Single'}
                                            checked={selectedOption === 'Single'} onChange={onRadioButtonChange}
                                            disabled={(comprehensionId !== undefined) || (payload.ofComprehension)}
                                        />
                                        <span className={'ms-1'}>Single</span>
                                    </label>
                                    <label>
                                        <input
                                            type={'radio'} name={'QuestionType'} value={'Passage'}
                                            checked={selectedOption === 'Passage'} onChange={onRadioButtonChange}
                                        />
                                        <span className={'ms-1'}>Passage</span>
                                    </label>
                                </div>
                                {selectedOption === 'Single' &&
                                    <div>
                                        <div className="mb-2 d-flex align-items-center">
                                            <div className='d-flex flex-row align-items-center w-100'>
                                                <div className='w-100'>
                                                    <label className="form-label" htmlFor={'type'}>Question Type<span
                                                        className='ms-1 text-danger'>*</span></label>
                                                    <select required={true} id="type" className="form-select w-100"
                                                            onChange={(event) => {
                                                                handleDropDownSelect(event);
                                                                handleChange(event);
                                                            }}
                                                            value={payload.type} name="type">
                                                        <option value="">Select</option>
                                                        <option value={'MultiSelect'}>MultiSelect</option>
                                                        <option value={'OneAnswer'}>One Answer</option>
                                                        <option value={'TrueFalse'}>True/False</option>
                                                        <option value={'OneWord'}>One Word</option>
                                                    </select>
                                                </div>
                                                <div className='ms-2 d-flex flex-row'>
                                                    {selectedQuestionType === ('TrueFalse') || selectedQuestionType === ('OneWord') ? '' :
                                                        <button type='button' className='mt-4 btnAdd'
                                                                style={{width: '120px'}}
                                                                onClick={handleAddOption}>
                                                            Add Options
                                                        </button>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-2'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <label className="form-label" htmlFor={'text'}>
                                                    Question<span className='ms-1 text-danger'>*</span>
                                                </label>
                                                <div className='mb-2'>
                                                    <button
                                                        type={'button'}
                                                        onClick={handleToggleImageUpload}
                                                        className='btn text-white btn-sm ms-2 me-3 createQuestionButton'>
                                                        {imageUploadVisible ? 'Close' : 'Upload Image'}
                                                    </button>
                                                    <button
                                                        className={'btn-outline-success btn bg-white text-success btn-sm ms-2 me-3'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowPreview(!showPreview);
                                                        }}>
                                                        {showPreview ? "Hide" : "Preview"}
                                                    </button>
                                                </div>
                                            </div>
                                            {imageUploadVisible && (
                                                <ImageUpload onUploadedFileCid={handleOnUploadedFileCid}
                                                             imageUrl={imageUrl}
                                                             image={(url) => handleImage(url)}/>
                                            )}
                                            <textarea id="text" className="form-control" name="text"
                                                      value={payload.text} onChange={handleChange}
                                                      placeholder='Create Question' required/>
                                        </div>
                                        {selectedQuestionType === "OneWord" ? (
                                            <div className='w-75'>
                                                <label htmlFor={'oneWordAnswer'}>Options<span
                                                    className='ms-1 text-danger mb-2'>*</span></label>
                                                <input id={'oneWordAnswer'} className="form-control mt-2 mb-3"
                                                       type="text" name={'oneWordAnswer'} placeholder={`Option`}
                                                       value={payload.oneWordAnswer}
                                                       onChange={(e) => handleChange(e)} required/>
                                            </div>
                                        ) : (
                                            <DropdownSelect
                                                options={options} selectedQuestionType={selectedQuestionType}
                                                handleOptionTextChange={handleOptionTextChange}
                                                handleOptionCorrectChange={handleOptionCorrectChange}
                                                handleOptionCorrectChanges={handleOptionCorrectChanges}
                                                handleRemoveOption={handleRemoveOption}
                                            />
                                        )}
                                        <div>
                                            <div className="mb-2 me-4  ">
                                                <label className="form-label"
                                                       htmlFor={'explanation'}>Explanation</label>
                                                <textarea id="explanation" className="form-control" name="explanation"
                                                          value={payload.explanation}
                                                          onChange={handleChange} placeholder='Explanation...'/>
                                            </div>
                                        </div>
                                        <QuestionListTable payload={payload} handleChange={handleDifficultyChange}/>
                                        <div className={'col-6 mb-2'}>
                                            <label className="form-label">Category</label>
                                            <input className="form-control mt-2 mb-3" type="text"
                                                   name={'category'} value={payload.category}
                                                   onChange={(e) => handleChange(e)}/>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            {location.pathname === '/teacher/audit' ? (
                                                <ResolveQuestion flaggedQuestionId={props.flaggedQuestionId ?? 0}
                                                                 onHide={props.onHide}
                                                                 filteredSubjectIds={props.filteredSubjectIds}
                                                                 selectedOption={props.selectedOption ?? ''}
                                                                 currentPage={props.currentPage ?? 0}
                                                                 pageSize={props.pageSize ?? 0}
                                                                 handleOnSuccess={() =>
                                                                     props.handleOnSuccess && props.handleOnSuccess('', [], [], props.filteredSubjectIds, props.selectedOption as any, 'UnResolved', props.currentPage, props.pageSize)}/>
                                            ) : ''
                                            }
                                        </div>
                                        {props.questionId && (location.pathname === '/teacher/audit' || location.pathname === '/teacher/Verification' || location.pathname === '/teacher/createTest') ? (
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <Button label={'Update'}
                                                            cssName={'btnCreateSubmit createQuestionButton'}
                                                            loading={loading}/>
                                                </div>) :
                                            (!props.questionId &&
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <Button label={'Create'}
                                                            cssName={'btnCreateSubmit createQuestionButton'}
                                                            loading={loading}/>
                                                </div>)
                                        }
                                    </div>
                                }
                                {selectedOption === 'Passage' &&
                                    <div>
                                        {props.questionId ?
                                            <UpdateComprehensionQuestion handleDropDownSelect={handleDropDownSelect}
                                                                         handleRemoveOption={handleRemoveOption}
                                                                         handleOptionCorrectChanges={handleOptionCorrectChanges}
                                                                         handleOptionCorrectChange={handleOptionCorrectChange}
                                                                         handleOptionTextChange={handleOptionTextChange}
                                                                         selectedQuestionType={selectedQuestionType}
                                                                         handleAddOption={handleAddOption}
                                                                         options={options} handleChange={handleChange}
                                                                         payload={payload}
                                                                         questionIdCreatedQuestionRes={questionId}
                                                                         onComprehensionIdChange={(id: number) => handleOnComprehensionIdChange(id)}
                                                                         questionId={props.questionId}
                                                                         createQuestion={createQuestion}
                                                                         getQuestionById={getQuestionById}
                                                                         updateQuestion={updateQuestion}
                                                                         setPayload={setPayload} setOptions={setOptions}
                                                                         showPreview={showPreview}
                                                                         setShowPreview={setShowPreview}
                                                                         passage={handlePassageChange}
                                                                         passageId={props.passageId}
                                                                         passgeText={props.passgeText ?? ''}
                                                                         setPassage={setPassage}
                                                                         initialPayload={initialPayload as Questions}
                                                                         onSuccess={props.onSuccess}
                                                                         setQuestionId={setQuestionId}
                                                                         hasIssue={props.hasIssue}
                                                                         setUpdateQuestionId={setUpdateQuestionId}
                                                                         flaggedQuestionId={props.flaggedQuestionId}
                                                // setFlaggedQuestionId={props.setFlaggedQuestionId}
                                                                         flaggedIds={props.flaggedIds ?? []}
                                                                         allFlaggedQuestionIds={props.allFlaggedQuestionIds ?? []}
                                                                         setImageUrl={hanleCompImage}
                                            />
                                            :
                                            <CreateComprehensionQuestion handleDropDownSelect={handleDropDownSelect}
                                                                         handleRemoveOption={handleRemoveOption}
                                                                         handleOptionCorrectChanges={handleOptionCorrectChanges}
                                                                         handleOptionCorrectChange={handleOptionCorrectChange}
                                                                         handleOptionTextChange={handleOptionTextChange}
                                                                         selectedQuestionType={selectedQuestionType}
                                                                         handleAddOption={handleAddOption}
                                                                         options={options} handleChange={handleChange}
                                                                         payload={payload}
                                                                         questionIdCreatedQuestionRes={questionId}
                                                                         onComprehensionIdChange={(id: number) => handleOnComprehensionIdChange(id)}
                                                                         questionId={props.questionId}
                                                                         createQuestion={createQuestion}
                                                                         getQuestionById={getQuestionById}
                                                                         updateQuestion={updateQuestion}
                                                                         setPayload={setPayload} setOptions={setOptions}
                                                                         showPreview={showPreview}
                                                                         setShowPreview={setShowPreview}
                                                                         passage={handlePassageChange}
                                                                         passageId={props.passageId}
                                                                         passgeText={props.passgeText ?? ''}
                                                                         initialPayload={initialPayload as Questions}
                                                                         onSuccess={props.onSuccess}
                                                                         setQuestionId={setQuestionId}
                                                                         updateQuestionId={updateQuestionId}
                                                                         setUpdateQuestionId={setUpdateQuestionId}
                                                                         flaggedQuestionId={props.flaggedQuestionId}
                                                                         setImageUrl={hanleCompImage}
                                                // setFlaggedQuestionId={props.setFlaggedQuestionId}
                                                // expandedPassageId={props.expandedPassageId ?? null}
                                            />
                                        }
                                        <div className={'d-flex align-items-center justify-content-center'}>
                                            <button onClick={() => {
                                                setSelectedTopic([]);
                                                setSelectedSubject([]);
                                                resetImageStates();
                                                setSelectedOption('Single')
                                                props.onSuccess()
                                            }} type={'button'}
                                                    className={'btn createQuestionButton text-white'}>
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                }
                            </form>)}
                        {selectedMethod === 'formula' && (
                            <div>
                                <MathJaxCheatSheet/>
                            </div>
                        )}
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}
export default CreateUpdateQuestions;