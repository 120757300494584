import React from 'react';

interface ProgressBarProps {
    totalPoints: number ;
    completedPoints: number;
    height: number;

}

const ProgressBar: React.FC<ProgressBarProps> = ({ totalPoints, completedPoints,height }) => {
    const progressPercentage = (completedPoints / totalPoints) * 100;

    const progressBarStyle: React.CSSProperties = {
        height: `${height}px`,
        width: '100%',
        backgroundColor: 'white',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.08)',
    };

    const progressStyle: React.CSSProperties = {
        height: '100%',
        width: `${progressPercentage}%`,
        backgroundColor: '#3498DB',
        transition: 'width 0.5s ease-in-out',
    };

    return (
        <div style={progressBarStyle}>
            <div style={progressStyle}></div>
        </div>
    );
};

export default ProgressBar;
