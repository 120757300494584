import "./studentCard.scss";
import {CourseBatch} from "../../../../../dto/CourseBatch";
import {OnClick} from "../../../../../dto/AppTypes";
import {useStudentApiClient} from "../../../apiClients/StudentApiClient";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {useNavigate} from "react-router-dom";

interface StudentCourseCardProps {
    courseBatch: CourseBatch,
    onClick: OnClick,
    onSuccess: () => void
    selectedOption?: string
    selectedTab?: string
}

export const StudentCourseCard = (props: StudentCourseCardProps) => {
    const studentApiClient = useStudentApiClient();
    const [deleteCourse, setDeleteCourse] = useState<number>(0);
    const currentDate = Date.now()
    const navigate = useNavigate()

    const unAssignCourseByStudent = (courseId: number) => {
        studentApiClient.unAssignCourseByStudent(deleteCourse ?? 0).then((res) => {
            props.onSuccess();
        }).catch((err) => {
            console.error("Course removal error", err);
        });
    };


    const handleSubmit = (e: any) => {
        e.preventDefault();
        unAssignCourseByStudent(deleteCourse ?? 0);
    };

    const getDaysDifference = (): string => {
        let days = moment(props.courseBatch.endDate).diff(moment.now(), "days");
        if (days > 1) {
            return `${days} days left`;
        } else if (days > 0) {
            return `${days} days left `;
        } else if (days < 0) {
            return "Trial Over";
        } else {
            let hours = moment(props.courseBatch.endDate).diff(moment.now(), "hours");
            return `Over in ${hours} hours left`;
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className={`student-course-card`} >
                <div className='card shadow-sm rounded-4 border-0 mb-3' >
                    <div className='d-flex justify-content-center align-items-center'
                         onClick={() => {
                             if (moment().isAfter(moment(props.courseBatch.endDate))) {
                                 console.log('Date is after the end date');
                             } else {
                                 props.onClick();
                             }
                         }}
                         style={{position:'relative'}}>
                        <div style={{width:'90%',position:'relative',marginTop:'10px'}}>
                            <img
                                src={props.courseBatch.imageUrl
                                    ? props.courseBatch.imageUrl
                                    : 'https://static.vecteezy.com/system/resources/previews/013/115/384/non_2x/cartoon-maths-elements-background-education-logo-vector.jpg'}
                                alt={''}
                                style={{
                                    height: 'auto',
                                    width: '100%',
                                    maxHeight: '170px',
                                    objectFit: 'cover',
                                    borderRadius: '5px'
                                }}
                            />
                            <div className='image-overlay'/>
                            {props.courseBatch.typeOfCourse === 'FREE_TRIAL' ? (
                                <div className='trial-button' >Free trial</div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                        {moment().isAfter(moment(props.courseBatch.endDate)) ? "" : (
                            <div className={'play-button'} >
                                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Frame" clip-path="url(#clip0_350_896)">
                                        <g id="Group">
                                            <path id="Vector" d="M32.9165 21.182L15.0993 33.0602C14.9727 33.1444 14.8256 33.1927 14.6738 33.2C14.5219 33.2073 14.3709 33.1732 14.2369 33.1014C14.1028 33.0297 13.9908 32.9229 13.9126 32.7925C13.8345 32.6621 13.7932 32.5129 13.7931 32.3609V8.60449C13.7932 8.45245 13.8345 8.30328 13.9126 8.17287C13.9908 8.04246 14.1028 7.93568 14.2369 7.86392C14.3709 7.79216 14.5219 7.7581 14.6738 7.76536C14.8256 7.77263 14.9727 7.82095 15.0993 7.90518L32.9165 19.7834C33.0317 19.8601 33.126 19.9641 33.1913 20.0861C33.2566 20.2081 33.2908 20.3443 33.2908 20.4827C33.2908 20.621 33.2566 20.7573 33.1913 20.8792C33.126 21.0012 33.0317 21.1052 32.9165 21.182Z" fill="white"/>
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_350_896">
                                            <rect width="40.3448" height="40.3448" fill="white" transform="translate(0.344818 0.310303)"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>
                        )}

                        {props.courseBatch.typeOfCourse === 'FREE_TRIAL' && moment().isAfter(moment(props.courseBatch.endDate)) ? (
                            <div className={'renew-button'} style={{borderRadius:'50%'}} >
                                <svg  xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.63874 11.0487V8.64868C6.63874 5.33498 9.32503 2.64868 12.6387 2.64868C15.9524 2.64868 18.6387 5.33498 18.6387 8.64868V11.0487C19.9643 11.0487 21.0387 12.1232 21.0387 13.4487V19.4487C21.0387 20.7742 19.9643 21.8487 18.6387 21.8487H6.63874C5.31325 21.8487 4.23874 20.7742 4.23874 19.4487V13.4487C4.23874 12.1232 5.31325 11.0487 6.63874 11.0487ZM16.2387 8.64868V11.0487H9.03874V8.64868C9.03874 6.66046 10.6505 5.04868 12.6387 5.04868C14.627 5.04868 16.2387 6.66046 16.2387 8.64868Z" fill="white"/>
                                </svg>
                            </div>
                        ) : moment().isBetween(moment(props.courseBatch.createdDate), moment(props.courseBatch.endDate)) ? "" : (
                            <div className={'renew-button'} onClick={() => {
                                navigate(`course-details/${props.courseBatch.id}`)
                            }}>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.63874 11.0487V8.64868C6.63874 5.33498 9.32503 2.64868 12.6387 2.64868C15.9524 2.64868 18.6387 5.33498 18.6387 8.64868V11.0487C19.9643 11.0487 21.0387 12.1232 21.0387 13.4487V19.4487C21.0387 20.7742 19.9643 21.8487 18.6387 21.8487H6.63874C5.31325 21.8487 4.23874 20.7742 4.23874 19.4487V13.4487C4.23874 12.1232 5.31325 11.0487 6.63874 11.0487ZM16.2387 8.64868V11.0487H9.03874V8.64868C9.03874 6.66046 10.6505 5.04868 12.6387 5.04868C14.627 5.04868 16.2387 6.66046 16.2387 8.64868Z" fill="white"/>
                                </svg>
                                Renew Now
                            </div>
                        )}

                    </div>

                    <div className={'d-flex justify-content-center align-items-center pb-2'}>
                       <div className=' 'style={{width:'90%'}}>
                           <div className='mt-2'>
                               {props.courseBatch.active === false ?
                                   <div className="card-title " >
                                       <h5> {
                                           props.courseBatch.name
                                               ? props.courseBatch.name.length > 20
                                                   ? `${props.courseBatch.name.slice(0, 20)}...`
                                                   : props.courseBatch.name
                                               : 'name'
                                       }
                                       </h5>
                                   </div> :
                                   <div className="card-title ">
                                       <h5> {
                                           props.courseBatch.name
                                               ? props.courseBatch.name.length > 20
                                                   ? `${props.courseBatch.name.slice(0, 20)}...`
                                                   : props.courseBatch.name
                                               : 'name'
                                       }
                                       </h5>
                                   </div>
                               }
                               {props.selectedTab === 'Enrolled' && (props.selectedOption === "PAID" ) && (
                                   <div className={'d-flex justify-content-between align-items-center'} >
                                       <div className={''} >
                                           <i className="bi bi-file-earmark-text fs-5" style={{color: '#9E9E9E'}}></i>
                                           <span className='' style={{color: '#212121E5', fontSize:'15px',fontWeight:400}}> {props.courseBatch.programs?.length ?? '0'} Programs</span>
                                           <div className='m-1' style={{color: 'rgba(0,0,0,0.6)' , fontSize:'12px'}}>
                                               {
                                                   currentDate > Date.parse(String(props.courseBatch.endDate)) ?
                                                       <span  className={'text-danger'}>Expired on: {moment(props.courseBatch.endDate).format('Do MMM YYYY')}</span> :
                                                       <span  >Valid till: {moment(props.courseBatch.endDate).format('Do MMM YYYY')}</span>
                                               }
                                           </div>


                                       </div>
                                       {/*<div className={'res_btn'}>*/}
                                       {/*    <div>*/}
                                       {/*        <div>*/}
                                       {/*            {moment().isBetween(moment(props.courseBatch.createdDate), moment(props.courseBatch.endDate)) ? "" : (*/}
                                       {/*                <button className={'buyNowForFreeTrail'}*/}
                                       {/*                        type={'button'}*/}
                                       {/*                        onClick={() => {*/}
                                       {/*                            navigate(`course-details/${props.courseBatch.id}`)*/}
                                       {/*                        }}>Renew*/}
                                       {/*                </button>*/}
                                       {/*            )}*/}

                                       {/*        </div>*/}
                                       {/*    </div>*/}
                                       {/*</div>*/}
                                   </div>
                               )}
                               {props.selectedTab === 'Enrolled' && (props.selectedOption === "All")  && (
                                   <div className={'d-flex justify-content-between align-items-center '}>
                                       <div className={''}>
                                           <i className="bi bi-file-earmark-text fs-5" style={{color: '#9E9E9E'}}></i>
                                           <span className='' style={{color: '#9E9E9E', fontSize:'15px'}}> {props.courseBatch.programs?.length ?? '0'} Programs</span>
                                           <div className='m-1' style={{color: 'rgba(0,0,0,0.6)' , fontSize:'12px'}}>
                                               {
                                                   currentDate > Date.parse(String(props.courseBatch.endDate)) ?
                                                       <span  className={'text-danger'}>Expired on: {moment(props.courseBatch.endDate).format('Do MMM YYYY')}</span> :
                                                       <span  >Valid till: {moment(props.courseBatch.endDate).format('Do MMM YYYY')}</span>
                                               }
                                           </div>
                                       </div>
                                       <div className={'res_btn'}>
                                           {props.courseBatch.typeOfCourse === 'FREE_TRIAL'  &&
                                               <div>
                                                   <div>
                                                       {(
                                                           <button className={'buyNowForFreeTrail'}
                                                                   type={'button'}
                                                                   onClick={() => {
                                                                       navigate(`course-details/${props.courseBatch.id}`)
                                                                   }}>Buy Now
                                                           </button>
                                                       )}
                                                   </div>
                                               </div>
                                           }
                                       </div>
                                   </div>
                               )}
                               {props.selectedTab === 'Enrolled' && (props.selectedOption === "FREE_TRIAL")  && (
                                   <div className={'d-flex justify-content-between align-items-center '}>
                                       <div className={''}>
                                           <i className="bi bi-file-earmark-text fs-5" style={{color: '#9E9E9E'}}></i>
                                           <span className='' style={{color: '#9E9E9E', fontSize:'15px'}}> {props.courseBatch.programs?.length ?? '0'} Programs</span>
                                           <div className='m-1' style={{color: 'rgba(0,0,0,0.6)' , fontSize:'12px'}}>
                                               {
                                                   currentDate > Date.parse(String(props.courseBatch.endDate)) ?
                                                       <span  className={'text-danger'}>Expired on: {moment(props.courseBatch.endDate).format('Do MMM YYYY')}</span> :
                                                       <span  >Valid till: {moment(props.courseBatch.endDate).format('Do MMM YYYY')}</span>
                                               }
                                           </div>
                                       </div>
                                       <div className={'res_btn'}>
                                           {props.selectedTab === 'Enrolled' && (props.selectedOption === 'FREE_TRIAL') &&
                                               <div>
                                                   <div>
                                                       {(
                                                           <button className={'buyNowForFreeTrail'}
                                                                   type={'button'}
                                                                   onClick={() => {
                                                                       navigate(`course-details/${props.courseBatch.id}`)
                                                                   }}>Buy Now
                                                           </button>
                                                       )}
                                                   </div>
                                               </div>
                                           }
                                       </div>
                                   </div>
                               )}
                           </div>
                       </div>
                   </div>
                </div>
                <div className="card shadow-sm rounded-4 border-0">
                    {/*{props.selectedTab === 'Enrolled' && (props.selectedOption === 'FREE_TRIAL') &&*/}
                    {/*    <div className={'d-flex justify-content-between align-items-start px-3'} style={{border: '4px solid red'}}>*/}
                    {/*        <div className='mt-2'>*/}
                    {/*            <div className={'crd_date mt-2 ps-2'} style={{*/}
                    {/*                textAlign:'center',*/}
                    {/*                padding:'1px 30px',*/}
                    {/*                fontSize:'12px',*/}
                    {/*                backgroundColor: getDaysDifference() === 'Trial Over' ? '#D9D9D9' : '#9FCBD4',*/}
                    {/*                borderRadius: '10px',*/}
                    {/*                color: getDaysDifference() === 'Trial Over' ? 'gray' : 'white'*/}
                    {/*            }}>*/}
                    {/*                <span>{getDaysDifference()}</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className={'mt-2'}>*/}
                    {/*            {moment().isAfter(moment(props.courseBatch.endDate)) ? "" : (*/}
                    {/*                <i className={`bi bi-play-circle fs-4`} onClick={() => props.onClick()}*/}
                    {/*                   style={{color: '#3498DB', cursor: 'pointer'}}></i>*/}
                    {/*            )}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    {/*<div className="card-body p-0">*/}
                    {/*    <div className={'d-flex justify-content-between align-items-start px-3'}>*/}
                    {/*        <div className='mt-2'>*/}
                    {/*            {props.courseBatch.active === false ?*/}
                    {/*                <div className="card-title pt-2">*/}
                    {/*                    <h5> {*/}
                    {/*                        props.courseBatch.name*/}
                    {/*                            ? props.courseBatch.name.length > 20*/}
                    {/*                                ? `${props.courseBatch.name.slice(0, 20)}...`*/}
                    {/*                                : props.courseBatch.name*/}
                    {/*                            : 'name'*/}
                    {/*                    }*/}
                    {/*                    </h5>*/}
                    {/*                </div> :*/}
                    {/*                <div className="card-title pt-2">*/}
                    {/*                    <h5> {*/}
                    {/*                        props.courseBatch.name*/}
                    {/*                            ? props.courseBatch.name.length > 20*/}
                    {/*                                ? `${props.courseBatch.name.slice(0, 20)}...`*/}
                    {/*                                : props.courseBatch.name*/}
                    {/*                            : 'name'*/}
                    {/*                    }*/}
                    {/*                    </h5>*/}
                    {/*                </div>*/}
                    {/*            }*/}
                    {/*        </div>*/}

                    {/*        <div className="more_btn dropdown mb-2 mt-2">*/}
                    {/*            <div>*/}
                    {/*                {props.selectedTab === 'Enrolled' && props.selectedOption === 'PAID' &&*/}
                    {/*                    <div>*/}
                    {/*                        {moment().isAfter(moment(props.courseBatch.endDate)) ? "" : (*/}
                    {/*                        <i className={`bi bi-play-circle fs-4`} onClick={() => props.onClick()}*/}
                    {/*                           style={{color: '#3498DB', cursor: 'pointer'}}></i>*/}
                    {/*                            )}*/}
                    {/*                    </div>*/}
                    {/*                }*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    {props.selectedTab === 'Enrolled' && (props.selectedOption === "PAID") && (*/}
                    {/*        <div className={'d-flex justify-content-between align-items-center px-3 pb-3'}>*/}
                    {/*            <div className={''} >*/}
                    {/*                <div>*/}
                    {/*                    {*/}
                    {/*                        currentDate > Date.parse(String(props.courseBatch.endDate)) ?*/}
                    {/*                            <span className={'text-danger'}>Expired on: {moment(props.courseBatch.endDate).format('Do MMM YYYY')}</span> :*/}
                    {/*                            <span>Valid till: {moment(props.courseBatch.endDate).format('Do MMM YYYY')}</span>*/}
                    {/*                    }*/}
                    {/*                </div>*/}
                    {/*                <i className="bi bi-file-earmark-text fs-5" style={{color: '#9E9E9E'}}></i> <span*/}
                    {/*                className='fs-7'*/}
                    {/*                style={{color: '#9E9E9E'}}>{props.courseBatch.programs?.length ?? '0'} Programs</span>*/}
                    {/*            </div>*/}
                    {/*            <div className={'res_btn'}>*/}
                    {/*                <div>*/}
                    {/*                    <div>*/}
                    {/*                        {moment().isBetween(moment(props.courseBatch.createdDate), moment(props.courseBatch.endDate)) ? "" : (*/}
                    {/*                            <button className={'buyNowForFreeTrail'}*/}
                    {/*                                    type={'button'}*/}
                    {/*                                    onClick={() => {*/}
                    {/*                                        navigate(`course-details/${props.courseBatch.id}`)*/}
                    {/*                                    }}>Renew*/}
                    {/*                            </button>*/}
                    {/*                        )}*/}

                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    )}*/}

                    {/*    {props.selectedTab === 'Enrolled' && (props.selectedOption === "FREE_TRIAL") && (*/}
                    {/*        <div className={'d-flex justify-content-between align-items-center px-3 pb-3'}>*/}
                    {/*            <div className={''}>*/}
                    {/*                <i className="bi bi-file-earmark-text fs-5" style={{color: '#9E9E9E'}}></i> <span*/}
                    {/*                className='fs-7'*/}
                    {/*                style={{color: '#9E9E9E'}}>{props.courseBatch.programs?.length ?? '0'} Programs</span>*/}
                    {/*            </div>*/}
                    {/*            <div className={'res_btn'}>*/}
                    {/*                {props.selectedTab === 'Enrolled' && (props.selectedOption === 'FREE_TRIAL') &&*/}
                    {/*                    <div>*/}
                    {/*                        <div>*/}
                    {/*                            {(*/}
                    {/*                                <button className={'buyNowForFreeTrail'}*/}
                    {/*                                        type={'button'}*/}
                    {/*                                        onClick={() => {*/}
                    {/*                                            navigate(`course-details/${props.courseBatch.id}`)*/}
                    {/*                                        }}>Buy Now*/}
                    {/*                                </button>*/}
                    {/*                            )}*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                }*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>
            </div>
        </form>
    );
};