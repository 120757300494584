import {Link} from 'react-router-dom';
import './home.scss';
import Form from 'react-bootstrap/Form';
import React from "react";
import logo from "../../assets/images/aiDronaLogo.png"
import loginScrnImg1 from "../../assets/images/Rectangle 98.png";
import loginAsAdmin from "../../assets/images/loginAsAdmin.png";
import loginAsTeacher from "../../assets/images/loginAsTeacher.png";
import loginAsStudent from "../../assets/images/loginStudent.png";

export const Home = () => {
    return <div id={'main_wrapper'} className="loginScreen_wrapper">
        <div className="container-fluid">
            <div className="row min-vh-100">
                <div className="col-md-6">
                    <div className="login-wrap ms-sm-5">
                        <div>
                            <img style={{width: '79px', height: '65px'}} src={logo}/>
                        </div>
                        <div className={'login_info_wrap'}>
                            <h3 className='mb-4 fw-bold'>Welcome to Ai Drona</h3>
                            <h6 className='mb-4 text-black-50'>Login to your account</h6>
                            <div className="app-routes">
                                <Link to="/admin/login">
                                    <div className={'loginAs d-flex justify-content-between align-items-center'}>
                                        <div className={'d-flex align-items-center'}>
                                            <img src={loginAsAdmin} className={'me-3'} height={40}/>
                                            <h6>Login as Admin</h6>
                                        </div>
                                        <div>
                                            <i className="bi bi-arrow-right fs-4 text-black-50"></i>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/teacher/login">
                                    <div className={'loginAs d-flex justify-content-between align-items-center'}>

                                        <div className={'d-flex align-items-center'}>
                                            <img src={loginAsTeacher} className={'me-3'} height={40}/>
                                            <h6>Login as Teacher</h6>
                                        </div>
                                        <div>
                                            <i className="bi bi-arrow-right fs-4 text-black-50"></i>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/student/login">
                                    <div className={'loginAs d-flex justify-content-between align-items-center'}>

                                        <div className={'d-flex align-items-center'}>
                                            <img src={loginAsStudent} className={'me-3'} height={40}/>
                                            <h6>Login as Student</h6>
                                        </div>
                                        <div>
                                            <i className="bi bi-arrow-right fs-4 text-black-50"></i>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        </div>
                        <p className={'text-black-50 position-absolute bottom-0'}>
                            Powered by Carvia technologies
                        </p>
                    </div>
                </div>
                <div className={'col-md-6 right-side_tc'}>
                    <img src={loginScrnImg1} height={580}/>
                </div>
            </div>
        </div>
    </div>;
}