import React, { useEffect, useState } from "react";
import "./QuizStepper.scss";
import { Question } from "../../../../../modals/quizquestionData/Modal";

export interface QuestionData {
    question: Question,
    selectedChoice?: number[],
    attempted?: boolean,
    bookMark?: boolean,
    like?: boolean,
    status?: string,
    answer?: string
}

export interface StepperProps {
    currentStep: number;
    questionData: QuestionData[],
    onClick: (index: number) => void,
    viewType?: string
}

const QuizStepper = (props: StepperProps) => {
    const [questions, setQuestions] = useState<QuestionData[]>([]);
    const [visibleRange, setVisibleRange] = useState({ start: 0, end: 9 });

    useEffect(() => {
        setQuestions(props.questionData);
    }, [props.questionData]);

    const numCols = 5; // Number of columns

    useEffect(() => {
        const currentStep = props.currentStep;
        if (currentStep < visibleRange.start || currentStep > visibleRange.end) {
            const newStart = Math.floor(currentStep / 10) * 10;
            const newEnd = Math.min(newStart + 9, questions.length - 1);
            setVisibleRange({ start: newStart, end: newEnd });
        }
    }, [props.currentStep, questions.length]);


    const handleNext = () => {
        const newStart = Math.min(visibleRange.start + 10, questions.length - 1);
        const newEnd = Math.min(newStart + 9, questions.length - 1);
        setVisibleRange({ start: newStart, end: newEnd });
    };

    const handlePrev = () => {
        const newStart = Math.max(visibleRange.start - 10, 0);
        const newEnd = newStart + 9;
        setVisibleRange({ start: newStart, end: newEnd });
    };

    const visibleQuestions = questions.slice(visibleRange.start, visibleRange.end + 1);
    const numRows = Math.ceil(visibleQuestions.length / numCols);

    return (
        <div>
            <div className="Question-header">
                <h5 className="mt-2" style={{ fontWeight: '600' }}>Questions</h5>
            </div>
            <div className="d-flex stepper-container pt-2">
                <div className="stepper-navigation d-flex align-items-center" style={{ width: '10%' }}>
                    {visibleRange.start > 0 && (
                        <button onClick={handlePrev}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 48 49" fill="none">
                                <g clipPath="url(#clip0_315_265)">
                                    <path d="M35.0287 8.70748L31.4687 5.16748L11.6887 24.9675L31.4887 44.7675L35.0287 41.2275L18.7687 24.9675L35.0287 8.70748Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_315_265">
                                        <rect width="48" height="48" fill="white" transform="translate(0.00866699 0.967529)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    )}
                </div>
                <div className="stepper" style={{ width: '80%' }}>
                    {Array.from({ length: numRows }, (_, rowIndex) => (
                        <div className="stepper-row" key={rowIndex}>
                            {Array.from({ length: numCols }, (_, colIndex) => {
                                const index = rowIndex * numCols + colIndex;
                                const step = visibleQuestions[index];
                                if (!step) {
                                    return null;
                                }
                                const isSelected: boolean = step.selectedChoice !== undefined && step.selectedChoice.length > 0 ||
                                    (step.answer !== undefined && step.answer !== "");

                                return (
                                    <div key={index}>
                                        <div
                                            onClick={() => props.onClick(visibleRange.start + index)}
                                            className={`step ${
                                                isSelected ? "answered" :
                                                    step.bookMark ? "bookMark" :
                                                        step.attempted ? "notAnswered" : ""
                                            } ${props.currentStep === visibleRange.start + index ? "current" : ""}`}>
                                            <label className="radio-label">
                                                <span className="radio-number">{visibleRange.start + index + 1}</span>
                                            </label>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
                <div className="stepper-navigation d-flex align-items-center" style={{ width: '10%' }}>
                    {visibleRange.end < questions.length - 1 && (
                        <button onClick={handleNext}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 48 49" fill="none">
                                <g clip-path="url(#clip0_315_265)">
                                    <path d="M12.9886 8.70748L16.5486 5.16748L36.3286 24.9675L16.5286 44.7675L12.9886 41.2275L29.2486 24.9675L12.9886 8.70748Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_315_265">
                                        <rect width="48" height="48" fill="white" transform="matrix(-1 0 0 1 48.0087 0.967529)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QuizStepper;
