import React, {useEffect, useState} from "react";
import {StudentTopicCard} from "./card/StudentTopicCard";
import {useStudentApiClient} from "../../apiClients/StudentApiClient";
import {useNavigate, useParams} from "react-router-dom";
import StudentChapterShimmer from "../chapter/StudentChapterShimmer";
import {EmptyState} from "../../../../components/emptyState/EmptyState";
import TabSelector from "../tabSelector/TabSelector";
import "./StudentTopics.scss"
import {QuizMode} from "../../../../modals/quizquestionData/Modal";
import {StudentNoOfQuestionsModal} from "./StudentNoOfQuestionsModal";
import {ProgramRepo} from "../../../../repos/ProgramRepo";

export interface Topics {
    id: number;
    name: string;
    hasComp?:boolean


}

const StudentTopicPaid = () => {
    const studentApiClient = useStudentApiClient();
    const params = useParams();
    const [selectAll, setSelectAll] = useState(false);
    const [topics, setTopics] = useState<Topics[]>([]);
    const [selectedCardIds, setSelectedCardIds] = useState<number[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedOption, setSelectedOption] = useState(QuizMode.TEST);
    const [show, setShow] = useState<boolean>(false)
    const [numberOfQuestions, setNumberOfQuestions] = useState('');
    const [canHaveComprehensions, setCanHaveComprehensions] = useState<boolean>(false);
    const [ofComp, setOfComp] = useState<boolean>(false);
    const [numberOfComp, setNumberOfComp] = useState<number | undefined>(undefined);

    const getAllTopics = () => {
        studentApiClient
            .getAllProgramTopics(parseInt(params?.programId ?? ""))
            .then((res: any) => {
                setTopics(res.data);
                setLoading(false);
            })
            .catch(() => {
                console.log("#30");
            });
    };

    useEffect(() => {
        getAllTopics();
        getProgramId(params.programId || '0');
    }, [params.programId]);


    const getProgramId = (programId: string) => {
        ProgramRepo.getSingleProgram(programId).then((res) => {
            setCanHaveComprehensions(res.data.canHaveComprehensions);
        });
    };

    const toggleSelectAll = () => {
        if (selectAll) {
            setSelectedCardIds([]);
        } else {
            const allTopicIds = topics
                .filter((topic) => !(ofComp && !topic.hasComp))
                .map((topic) => topic.id);
            setSelectedCardIds(allTopicIds);
        }
        setSelectAll(!selectAll);
    };

    const toggleCardSelection = (cardId: number) => {
        setSelectedCardIds((prevSelected) => {
            const updatedSelected = prevSelected.includes(cardId)
                ? prevSelected.filter((id) => id !== cardId)
                : [...prevSelected, cardId];

            const allTopicsSelected = topics.every((topic) => updatedSelected.includes(topic.id));

            setSelectAll(allTopicsSelected);

            return updatedSelected;
        });
    };
    const handleNumberOfQuestions = (number: string) => {
        setNumberOfQuestions(number);
        console.log(number)
    };

    const handleNumberOfComp = (number: number) => {
        setNumberOfComp(number);
    }

    const createTest = () => {
        if (ofComp) {
            studentApiClient.createCompTest(Number(params.courseBatchId), selectedCardIds,
                Number(params.programId), selectedOption, numberOfComp).then((res) => {
                navigate(`../${selectedOption}/${res.data.id}/comp-instructions`)
            }).catch(() => {
            })
        } else {
            studentApiClient
                .createTest(Number(params.courseBatchId), selectedCardIds, Number(params.programId), selectedOption,studentRank.percentage, parseInt(numberOfQuestions, 10))
                .then((res) => {
                    // console.log("Test created successfully!", res);
                    navigate(`../${selectedOption}/${res.data.id}/instructions`);
                    setNumberOfQuestions('');
                    console.log('studentRank',studentRank)

                })
                .catch((error) => {
                    console.log('studentRank',studentRank)

                    console.error("Error creating test:", error);
                });
        }
    };

    const [studentRank, setStudentRank] = useState<any>();
    const getStudentRankForAllCourses = () => {
        studentApiClient.getStudentRank(params.courseBatchId ?? 0 as any).then((res) => {
            setStudentRank(res.data)
            console.log('studentRank',studentRank)
        }).then((error) => {
            console.log(error)
        })
    }
    useEffect(() => {
        getStudentRankForAllCourses()
    }, []);

    const handleOptionClick = (options: string) => {
        console.log('options', options)
        if (options === 'TEST')
            setSelectedOption(QuizMode.TEST);
        else if (options === 'PRACTICE') {
            setSelectedOption(QuizMode.PRACTISE)
        }
    };

    const handleComprehensionCheckboxChange = (e:any) => {
        setOfComp(e.target.checked);
        setSelectedCardIds([]);
        setSelectAll(false);

    };


    return <div>
        {loading ? <StudentChapterShimmer count={10}/> : <div>
            {topics.length > 0 ? <div className="container">
                <div className={'row topicHeader mt-4 mb-4 align-items-end'}>
                    <div className={'col-md-12 wrapper_c'}>
                        <div>
                            <div>
                                <h6 className={'fw-bold'}>Choose Topics</h6>
                            </div>
                            <div className={'d-flex'}>
                                <TabSelector option={selectedOption} handleOptionClick={handleOptionClick}/>
                            </div>
                        </div>
                        <div className="d-sm-flex align-items-center wrap_c">
                            <div className={`bg-white py-1 px-3 rounded-4 border`}
                                 onClick={toggleSelectAll}>
                                <input
                                    type="checkbox"
                                    className="inputCheckbox mx-2"
                                    checked={selectAll}
                                    onChange={toggleSelectAll}
                                />
                                <span className="chapter-label">Select All Topics</span>

                            </div>
                            <div className={'chip_w'}>
                                {
                                    canHaveComprehensions ?
                                        <div className={`bg-white py-1 px-3 rounded-4 border ms-sm-4 mt-3 mt-sm-0`}>
                                            <input
                                                type="checkbox"
                                                className="inputCheckbox me-3"
                                                checked={ofComp}
                                                onChange={handleComprehensionCheckboxChange}
                                            />
                                            <span>Only comprehensions</span>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row multi-selector mt-2 mb-6">

                    {topics?.map((card, index) => (
                        <div className={`col-md-4 mb-4`} key={card.id}>
                            <StudentTopicCard data={card} selected={selectedCardIds.includes(card.id)}
                                              toggleSelection={() => toggleCardSelection(card.id)}   disabled={!card.hasComp && ofComp === true}
                            />
                        </div>
                    ))}
                </div>
                <div className="sticky-bottom-btns text-end">
                    <button className={"btn btn-success text-white me-3"} onClick={() => setShow(true)}
                            disabled={!(selectedCardIds.length > 0)}>
                        {selectedOption === QuizMode.PRACTISE ? "Start Practice Test" : "Start Test"}
                    </button>
                </div>
            </div> : <EmptyState/>
            }
        </div>
        }
        <StudentNoOfQuestionsModal
            show={show}
            onHide={() => setShow(false)}
            createTest={() => createTest()}
            numberOfQuestions={handleNumberOfQuestions}
            ofComp={ofComp}
            numberOfComp={handleNumberOfComp}
        />
    </div>
}
export default StudentTopicPaid;